<template lang="pug">
   .container
    h1.title.is-2 Settings
    h2.title.is-5(v-if="!isLoggedIn") 
      router-link(to='/login') Login 
      | to update your personal settings
    template(v-else)
      .container.loader.large-loader(v-if="isLoading")
      .template(v-else)
        h2.title.is-5 Username
          span.explanation  (used when sharing your collection)
        .columns
          .column.is-4
            form.field.has-addons(@submit.prevent="saveUsername()")
              .control
                input.input(v-model='username' type='text' pattern='[A-Za-z]+' maxlength='20')
              .control
                button.button.is-primary Save
        h2.title.is-5 Choose what to track
        .columns.is-vcentered
          .column.is-narrow
            label.label.tracker-label Built-in trackers: 
          .column
            .collection-buttons
              button.button.collection-button.is-small(
                :class="{ 'is-dark': isTracked('collected')}",
                @click.stop="toggleTracked('collected')"
              )
                font-awesome-icon(:icon="['fas', 'check']", v-if="isTracked('collected')")
                font-awesome-icon(:icon="['far', 'square']", v-else)
              button.button.collection-button.is-small(
                :class="{ 'is-male': isTracked('male') }",
                @click.stop="toggleTracked('male')"
              )
                font-awesome-icon(:icon="['fas', 'mars']")
              button.button.collection-button.is-small(
                :class="{ 'is-female': isTracked('female') }",
                @click.stop="toggleTracked('female')"
              )
                font-awesome-icon(:icon="['fas', 'venus']")
              button.button.collection-button.is-small(
                :class="{ 'is-purified': isTracked('purified') }",
                @click.stop="toggleTracked('purified')"
              )
                img.collection-image(src="@/assets/purified.png")
              button.button.collection-button.is-small(
                :class="{ 'is-lucky': isTracked('lucky') }",
                @click.stop="toggleTracked('lucky')"
              )
                img.collection-image(src="@/assets/three-leaf-clover.png")
              button.button.collection-button.is-small(
                :class="{ 'is-shiny': isTracked('shiny') }",
                @click.stop="toggleTracked('shiny')"
              )
                img.collection-image(src="@/assets/shiny-stars.png")
        .columns.is-vcentered
          .column.is-narrow
            label.label.tracker-label Custom trackers: 
          .column
            .collection-buttons
              button.button.collection-button.is-small(
                v-for='(tracker, key) in customTrackers', :key='key'
                :style="{ 'background-color': tracker.color, 'color': textColor(tracker.color) }",
                @click.stop="editTracker(key, tracker.name, tracker.color)"
              )
                span {{ tracker.name }}
              button.button.collection-button.is-small(
                v-if="customTrackerCount < 10"
                @click.stop="editTracker()"
              )
                span + Add
</template>

<script>
import { db } from '@/db';
import ColorPicker from '@/components/ColorPicker';
import EditTracker from '@/components/EditTracker';
import { isLight } from '@/utils/color-utils.js';

export default {
  name: 'Settings',
  components: {
    ColorPicker,
  },
  data() {
    return {
      isLoading: true,
      username: '',
      color: '',
      settings: {},
    };
  },
  created() {
    const userUid = localStorage.getItem('userUid');
    this.$bind('settings', db.database.ref('users/' + userUid + '/settings')).then(() => {
      this.username = this.settings.username;
      this.isLoading = false;
    });
  },
  computed: {
    isLoggedIn() {
      return Boolean(localStorage.getItem('userUid'));
    },
    customTrackers() {
      return this.settings.tracked && this.settings.tracked.custom;
    },
    customTrackerCount() {
      if (this.settings.tracked && this.settings.tracked.custom) {
        return Object.keys(this.settings.tracked.custom).length;
      }
      return 0;
    },
  },
  methods: {
    saveUsername() {
      const userUid = localStorage.getItem('userUid');
      db.database
        .ref('users/' + userUid + '/settings')
        .child('username')
        .set(this.username)
        .then(() => {
          this.$toast.open({
            message: 'Username saved.',
            queue: false,
          });
        })
        .catch(() => {
          this.$toast.open({
            message: 'Failed to save!',
            queue: false,
            type: 'is-danger',
          });
        });
    },
    textColor(backgroundColor) {
      return isLight(backgroundColor) ? 'black' : 'white';
    },
    editTracker(trackerId, name = 'Custom', color = '#1E8B6A') {
      this.$modal.open({
        parent: this,
        component: EditTracker,
        width: 400,
        props: {
          trackerId,
          name,
          color,
        },
      });
    },
    isTracked(collectedType) {
      return this.settings.tracked && this.settings.tracked[collectedType];
    },
    toggleTracked(collectedType) {
      const newStatus = !this.settings.tracked[collectedType];
      this.$firebaseRefs.settings
        .child('tracked')
        .child(collectedType)
        .set(newStatus)
        .then(() => {
          this.$toast.open({
            message: newStatus ? `Now tracking ${collectedType}.` : `Stopped tracking ${collectedType}.`,
            queue: false,
          });
        })
        .catch(() => {
          this.$toast.open({
            message: 'Failed to save!',
            queue: false,
            type: 'is-danger',
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.explanation {
  font-size: 0.9em;
  font-weight: 400;
}

.tracker-label {
  min-width: 130px;
}
</style>
