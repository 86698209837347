<template lang="pug">
  .container.login
    .columns.is-centered
      .column.is-narrow
        .loader.large-loader(v-if="isLoading")
        template(v-else)
          //h2.subtitle On this page you can keep track of your pokemon go collection
          h2.subtitle Sign in to store your own pokedex collection
          .sign-in-buttons
            .columns
              .column
                a.google-signin-btn.blue(@click="loginWithGoogle()")
                  span.logo
                  span.text Sign in with Google
            .columns.is-hidden
              .column
                button.google-button(@click="loginAnonymously()")
                  span.text Sign in anonymously
</template>

<script>
import firebase from '@firebase/app';
import '@firebase/auth';

var provider = new firebase.auth.GoogleAuthProvider();

export default {
  name: 'Login',
  data() {
    return {
      isLoading: false,
    };
  },
  created() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        // User is signed in.
        if (user.isAnonymous) {
          localStorage.setItem('userUid', user.uid);
          this.finishLogin('anonymous', user.uid);
        }
        // ...
      } else {
        // User is signed out.
        // ...
      }
      // ...
    });
    const authRedirected = localStorage.getItem('authRedirected');
    if (!authRedirected || authRedirected === 'false') {
      return;
    }
    this.isLoading = true;
    firebase
      .auth()
      .getRedirectResult()
      .then(result => {
        // The signed-in user info.
        const user = result.user;

        if (user) {
          localStorage.setItem('userUid', user.uid);
          localStorage.setItem('userDisplayName', user.displayName);
          localStorage.setItem('userEmail', user.email);
          this.finishLogin('google', user.uid);
        } else {
          this.isLoading = false;
          localStorage.setItem('authRedirected', false);
        }
      })
      .catch(function (error) {
        console.log('error', error);
        this.isLoading = false;
        localStorage.setItem('authRedirected', false);
        // Handle Errors here.
        //var errorCode = error.code;
        //var errorMessage = error.message;
        // The email of the user's account used.
        //var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        //var credential = error.credential;
        // ...
      });
  },
  methods: {
    loginWithGoogle() {
      localStorage.setItem('authRedirected', true);
      firebase.auth().signInWithRedirect(provider);
    },
    loginAnonymously() {
      localStorage.setItem('authRedirected', true);
      firebase
        .auth()
        .signInAnonymously()
        .catch(function (error) {
          console.log('error', error);
          // Handle Errors here.
          // var errorCode = error.code;
          // var errorMessage = error.message;
          // ...
        });
    },
    async finishLogin(loginType, userUid) {
      localStorage.setItem('authRedirected', false);
      const userTypeRef = firebase.database().ref(`users/${userUid}/userType`);
      const userTypeSnapshot = await userTypeRef.once('value');
      if (userTypeSnapshot.val() === null) {
        await userTypeRef.set(loginType);
        await this.setTrackedDefaults(userUid);
      }
      this.$router.push({ name: 'collection' });
    },
    async setTrackedDefaults(userUid) {
      const trackedRef = firebase.database().ref(`users/${userUid}/settings/tracked`);
      const trackedSnapshot = await trackedRef.once('value');
      if (trackedSnapshot.val() === null) {
        trackedRef.update({
          collected: true,
          male: true,
          female: true,
          purified: true,
          lucky: true,
          shiny: true,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css?family=Roboto');

.login {
  text-align: center;
}

.google-signin-btn {
  display: inline-block;
  border-width: 0;
  font-family: 'Roboto', 'Lato', sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.54);
  letter-spacing: 0.22px;
  text-shadow: none;
  padding: 0;

  &.blue {
    background: #4285f4;
    color: #ffffff;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
    border-radius: 2px;

    &:hover {
      background: #4285f4;
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
      border-radius: 2px;
      cursor: pointer;
    }

    &:active {
      background: #3367d6;
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
      border-radius: 2px;
    }
  }

  .logo {
    background: url('../assets/g-logo.png') #ffffff 50% 50% no-repeat;
    background-size: auto;
    background-size: 18px;
    height: 38px;
    width: 38px;
    margin: 1px;
    float: left;
    border-radius: 1px;
  }

  .text {
    text-align: left;
    height: 40px;
    line-height: 40px;
    margin: 0 12px;
  }
}
</style>
