<template lang="pug">
  .tags.has-addons.generation-tag
    span.tag.is-dark {{ generationNumber(generationId) }}
    span.tag.has-text-white(:style="{ backgroundColor: generationColor(generationId) }")
      | {{ generationName(generationId) }}
</template>

<script>
import { db } from '@/db';

export default {
  name: 'GenerationTag',
  props: {
    generationId: String,
  },
  data() {
    return {
      generations: {},
    };
  },
  async created() {
    this.generations = await db.generations();
  },
  methods: {
    generationName(generationId) {
      if (this.generations[generationId]) {
        return this.generations[generationId].name;
      }
      return '';
    },
    generationNumber(generationId) {
      if (this.generations[generationId]) {
        return this.generations[generationId].number;
      }
      return '';
    },
    generationColor(generationId) {
      if (this.generations[generationId] && this.generations[generationId].color) {
        return this.generations[generationId].color;
      }
      return 'grey';
    },
  },
};
</script>

<style lang="scss" scoped>
.generation-tag {
  border-radius: 4px;

  .tag:not(:last-child) {
    width: 25px;
  }
  .tag:not(:first-child) {
    width: 60px;
  }
}
</style>
