<template lang="pug">
  tr.placeholder(v-if="!isVisible")
  tr(v-else :class="{ 'is-selected': isSelected }" @click="toggleSelected")
    slot
</template>

<script>
export default {
  name: 'PokedexRow',
  data() {
    return {
      isSelected: false,
      isVisible: false,
    };
  },
  mounted() {
    this.observer = new IntersectionObserver(
      ([entry]) => {
        if (entry && entry.isIntersecting) {
          this.isVisible = true;
        }
      },
      { rootMargin: '200px' }
    );
    this.observer.observe(this.$el);
  },
  methods: {
    toggleSelected() {
      this.isSelected = !this.isSelected;
      this.$emit('selected', this.isSelected);
    },
    setSelected(selected) {
      this.isSelected = selected;
      this.$emit('selected', selected);
    },
  },
  destroyed() {
    this.observer.disconnect();
    if (this.isSelected) {
      this.$emit('selected', false);
    }
  },
};
</script>

<style scoped>
.placeholder {
  height: 43px;
}
</style>
