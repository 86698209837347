import firebase from '@firebase/app';
import '@firebase/database';

// Initialize Firebase
const config = {
  apiKey: 'AIzaSyADMcMpOKEKgLI3nI0OuAWzmW3Xs93BbXs',
  authDomain: 'pokestats.nu',
  databaseURL: 'https://pokestats2.firebaseio.com',
  projectId: 'pokestats2',
  storageBucket: 'pokestats2.appspot.com',
  messagingSenderId: '586058312865',
};

export const db = {
  database: null,
  app: null,
  init() {
    this.app = firebase.initializeApp(config);
    this.database = this.app.database();
    //firebase.database.enableLogging(true);
  },
  async adminlog() {
    return this.fetch('adminlog');
  },
  async pokedex() {
    return this.fetch('pokedex');
  },
  async pokemon(pokeNumber) {
    return this.fetch(`pokedex/${pokeNumber}`);
  },
  async generations() {
    return this.fetch('generations');
  },
  async userCollection(userUid) {
    return this.fetch(`users/${userUid}/pokemons`);
  },
  async pokemonCollected(userUid, pokeNumber) {
    return this.fetch(`users/${userUid}/pokemons/${pokeNumber}`);
  },
  async settings(userUid) {
    return this.fetch('users/' + userUid + '/settings');
  },
  async tracked(userUid) {
    return this.fetch('users/' + userUid + '/settings/tracked');
  },
  async username(userUid) {
    return this.fetch('users/' + userUid + '/settings/username');
  },
  async friends(userUid) {
    return this.fetch('users/' + userUid + '/friends');
  },
  async fetch(ref) {
    return new Promise(resolve => {
      firebase
        .database()
        .ref(ref)
        .once('value', snapshot => {
          resolve(snapshot.val());
        });
    });
  },
};
