<template lang="pug">
  div
    .modal-card(style='width: auto')
      header.modal-card-head
        p.modal-card-title Add Friend
      section.modal-card-body
        .field
          label.label ID/URL
          .control
            input.input(type='text', placeholder='Enter ID or URL' v-model='userId')
        .field
          label.label Nickname
          .control
            input.input.name-input(type='text', maxlength="20", placeholder='Enter nickname (optional)' v-model='nickname')
      footer.modal-card-foot
        button.button(type='button', @click='$parent.close()') Cancel
        button.button.is-link(@click="add()") Add
</template>

<script>
import { db } from '@/db';
import resolve from '@/utils/resolve.js';

export default {
  name: 'AddFriend',
  props: {
    userIdInput: String,
  },
  data() {
    return {
      userId: this.userIdInput || '',
      nickname: '',
      userUid: localStorage.getItem('userUid'),
    };
  },
  methods: {
    async add() {
      const userId = this.userId.substring(this.userId.lastIndexOf('/') + 1);
      const [error, userSnap] = await resolve(db.database.ref('users/' + userId).once('value'));
      if (error || !userSnap.val()) {
        this.$toast.open({
          message: 'User not found!',
          queue: false,
          type: 'is-danger',
        });
        return;
      }

      db.database
        .ref('users/' + this.userUid + '/friends/' + userId)
        .set({
          nickname: this.nickname,
        })
        .then(() => {
          this.$parent.close();
          this.$toast.open({
            message: 'Friend added.',
            queue: false,
          });
        })
        .catch(error => {
          console.log(error);
          this.$toast.open({
            message: 'Failed to add!',
            queue: false,
            type: 'is-danger',
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-card-foot {
  justify-content: flex-end;
}
</style>
