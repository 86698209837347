<template lang="pug">
  div
    .modal-card(style='width: auto')
      header.modal-card-head
        p.modal-card-title Uncollected
      section.modal-card-body
        .content
          p Filter the pokedex by selecting any of the collection types.
          p Shows pokemon 
            b not 
            | collected.
      footer.modal-card-foot
        button.button(type='button', @click='$parent.close()') Close
</template>

<script>
export default {
  name: 'UncollectedHelp',
};
</script>

<style lang="scss" scoped></style>
