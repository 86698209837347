<template lang="pug">
  div
    .modal-card(style='width: auto')
      header.modal-card-head
        p.modal-card-title Edit tracker
      section.modal-card-body
        .columns.is-vcentered.is-mobile
          .column.is-narrow
            label.label.edit-tracker-label Result:
          .column
            a.button.tracking-button.is-small(:style="{ 'background-color': colorValue, 'color': textColor }")
              | {{ nameValue }}
        hr
        .columns.is-vcentered.is-mobile.result-column
          .column.is-narrow
            label.label.edit-tracker-label Name:
          .column
            input.input.name-input(type='text', maxlength="15", placeholder='Name' v-model='nameValue')
        .columns.is-vcentered.is-mobile
          .column.is-narrow
            label.label.edit-tracker-label Color:
          .column
            color-picker(v-model='colorValue')
      footer.modal-card-foot
        button.button.is-pulled-right(type='button', @click='$parent.close()') Cancel
        button.button.is-pulled-right.is-danger(v-if='trackerId' @click="remove()") Remove
        button.button.is-pulled-right.is-primary(@click="save()") Save
</template>

<script>
import { db } from '@/db';
import ColorPicker from '@/components/ColorPicker';
import { isLight } from '@/utils/color-utils.js';

export default {
  name: 'EditTracker',
  components: {
    ColorPicker,
  },
  props: {
    trackerId: String,
    name: String,
    color: String,
  },
  data() {
    return {
      colorValue: this.color || '#1E8B6A',
      nameValue: this.name || '',
      customTrackers: null,
    };
  },
  created() {
    const userUid = localStorage.getItem('userUid');
    this.$bind('customTrackers', db.database.ref('users/' + userUid + '/settings/tracked/custom/'));
  },
  computed: {
    textColor() {
      return isLight(this.colorValue) ? 'black' : 'white';
    },
  },
  methods: {
    remove() {
      this.$firebaseRefs.customTrackers
        .child(this.trackerId)
        .remove()
        .then(() => {
          this.$parent.close();
          this.$toast.open({
            message: 'Tracker removed.',
            queue: false,
          });
        })
        .catch(() => {
          this.$toast.open({
            message: 'Failed to remove!',
            queue: false,
            type: 'is-danger',
          });
        });
    },
    save() {
      if (this.trackerId) {
        this.handleSavedTracker(
          this.$firebaseRefs.customTrackers.child(this.trackerId).set({
            active: true,
            name: this.nameValue,
            color: this.colorValue,
          })
        );
      } else {
        this.handleSavedTracker(
          this.$firebaseRefs.customTrackers.push({
            active: true,
            name: this.nameValue,
            color: this.colorValue,
          })
        );
      }
    },
    handleSavedTracker(savePromise) {
      savePromise
        .then(() => {
          this.$parent.close();
          this.$toast.open({
            message: 'Tracker saved.',
            queue: false,
          });
        })
        .catch(() => {
          this.$toast.open({
            message: 'Failed to save!',
            queue: false,
            type: 'is-danger',
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.tracking-button {
  width: 80px;
  font-weight: 600;
}

.edit-tracker-label {
  min-width: 55px;
}

.columns:not(:last-child) {
  margin-bottom: 0;
}

hr {
  margin-top: 0;
  margin-bottom: 1rem;
}

.name-input {
  width: 160px;
}

.modal-card-foot {
  justify-content: flex-end;
}
</style>
