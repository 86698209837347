<template lang="pug">
  #app
    //section.hero.is-dark.is-bold
      .hero-body
        .container
          b-dropdown.main-menu.is-pulled-right(
            v-if="isLoggedIn()"
            position='is-bottom-left',
            aria-role='menu'
          )
            a.navbar-item(slot='trigger', role='button')
              font-awesome-icon.menu-icon(:icon="['fas', 'bars']")
            b-dropdown-item(aria-role='menuitem', :custom='true')
              span
                | Logged in as&nbsp;
                b {{ userDisplayName() }}
            hr.dropdown-divider
            b-dropdown-item(aria-role='menuitem' @click="gotoSettings()")
              span.icon
                font-awesome-icon.menu-icon(:icon="['fas', 'cog']")
              span.menu-text
                | Settings
            b-dropdown-item(aria-role='menuitem' @click="importCsvFile()")
              span.icon
                font-awesome-icon.menu-icon(:icon="['fas', 'upload']")
              span.menu-text
                | Import from CSV file
            b-dropdown-item(aria-role='menuitem' @click="signOut()")
              span.icon
                font-awesome-icon.menu-icon(:icon="['fas', 'sign-out-alt']")
              span.menu-text
                | Log out
          router-link(to='/collection')
            h1.title.is-1 Pokestats
            h2.subtitle.max-width-80 Keep track of your collection
    nav.navbar.is-primary(role='navigation', aria-label='main navigation')
      .container
        .navbar-brand
          template(v-if="$route.params.uid")
            .navbar-item
              a(@click="$router.back()")
                font-awesome-icon.close-user( :icon="['fas', 'arrow-left']")
            .navbar-item.my-loader.pokestats-brand.center(:class="{ 'is-loading-small': isLoading }")
              | {{ username }}
          a.navbar-item(href="/" v-else)
            img(src="./assets/pokestats-logo.png")
            .pokestats-brand
              | {{ 'Pokestats' }}
          .navbar-item.is-hidden-desktop.center(v-if="!isLoggedIn()")
            .buttons
              router-link.button.is-light(to="/login")
                | Log in
          a.navbar-burger.burger(
            role='button',
            aria-label='menu',
            aria-expanded='false',
            data-target='navbarMenuId'
            @click='isMenuOpen = !isMenuOpen'
          )
            span(aria-hidden='true')
            span(aria-hidden='true')
            span(aria-hidden='true')
        #navbarMenuId.navbar-menu(:class="{ 'is-active': isMenuOpen }" @click='isMenuOpen = false')
          .navbar-end(v-if="$route.params.uid")
            router-link.navbar-item(to="/")
              | Home
            router-link.navbar-item(:to="`/collection/${$route.params.uid}`")
              | Collection
            router-link.navbar-item(:to="`/wishlist/${$route.params.uid}`")
              | Wishlist
            router-link.navbar-item(:to="`/overview/${$route.params.uid}`")
              | Overview
          .navbar-end(v-else)
            a.navbar-item(href="/")
              | Home
            router-link.navbar-item(to="/friends")
              | Friends
            router-link.navbar-item(to="/wishlist")
              | Wishlist
            router-link.navbar-item(to="/overview")
              | Overview
            router-link.navbar-item(to="/settings")
              | Settings
            router-link.navbar-item(v-if="isModerator" to="/admin")
              | Admin
            .navbar-item.has-dropdown.is-hoverable(v-if="isLoggedIn()")
              a.navbar-link
                | More
              .navbar-dropdown
                a.navbar-item(@click="importCsvFile()")
                  | Import from CSV file
                router-link.navbar-item(to="/pvpcounters")
                  | PvP Counters
                //a.navbar-item
                  | About
                //a.navbar-item
                  | Contact
                //hr.navbar-divider
                //a.navbar-item
                  | Report an issue
          .navbar-end
            .navbar-item.has-dropdown.is-hoverable(v-if="isLoggedIn()")
              a.navbar-link {{ userDisplayName() }}
              .navbar-dropdown
                a.navbar-item(@click="shareCollection()")
                  | Share collection
                a.navbar-item(@click="signOut()")
                  | Log out
            .navbar-item.is-hidden-mobile(v-else)
              .buttons
                router-link.button.is-light(to="/login")
                  | Log in
    .main-view
      section.section
        router-view(:key="$route.name + $route.params")
    footer.footer.main-footer.is-primary
      .container
        small
          | Content is © Copyright of Pokestats.nu 2019-2023.
        br
        small
          | Pokémon and all respective names are Trademark and © Copyright of Nintendo 1996-2023.
</template>

<script>
import { db } from '@/db';
import ImportCsvFile from '@/components/ImportCsvFile';
import ShareCollection from '@/components/ShareCollection';

export default {
  name: 'app',
  data() {
    return {
      isMenuOpen: false,
      isLoading: true,
      username: '',
      isModerator: false,
    };
  },
  created() {
    this.updateUsername();
    this.loadIsModerator();
  },
  computed: {
    usernameViewed() {
      if (this.$route.params.uid) {
        if (this.username['.value']) {
          return this.username['.value'];
        } else if (!this.isLoading) {
          return 'Anonymous';
        }
      }
      return '';
    },
  },
  watch: {
    // eslint-disable-next-line
    $route(to, from) {
      if (this.$route.params.uid && this.username) {
        document.title = `${this.username} - ${to.meta.title || 'Pokestats'}`;
      } else {
        document.title = to.meta.title || 'Pokestats';
      }
    },
    '$route.params.uid'(to, from) {
      console.log('User id changed!', to, from);
      this.username = '';
      this.isLoading = true;
      this.updateUsername();
    },
  },
  methods: {
    async loadIsModerator() {
      db.app.auth().onIdTokenChanged(async user => {
        if (user) {
          const idTokenResult = await user.getIdTokenResult();
          this.isModerator = Boolean(idTokenResult && idTokenResult.claims && !!idTokenResult.claims.moderator);
        }
      });
    },
    updateUsername() {
      if (this.$route.params.uid) {
        const usernameRef = db.database.ref(`users/${this.$route.params.uid}/settings/username`);
        usernameRef.once('value').then(usernameSnap => {
          this.isLoading = false;
          this.username = usernameSnap.val() || 'Anonymous';
          document.title = `${this.username} - ${document.title}`;
        });
      }
    },
    userDisplayName() {
      return localStorage.getItem('userDisplayName') || 'Anonymous';
    },
    isLoggedIn() {
      return Boolean(localStorage.getItem('userUid'));
    },
    signOut() {
      db.app.auth().signOut();
      localStorage.removeItem('userUid');
      localStorage.removeItem('userDisplayName');
      localStorage.removeItem('userEmail');
      this.$router.push('/');
      location.reload();
    },
    importCsvFile() {
      this.$modal.open({
        parent: this,
        component: ImportCsvFile,
      });
    },
    shareCollection() {
      this.$modal.open({
        parent: this,
        component: ShareCollection,
        width: 640,
      });
    },
    gotoSettings() {
      this.$router.push('settings');
    },
  },
};
</script>

<style lang="scss">
@import 'assets/pokestats-styles';

body {
  padding: 0;
  margin: 0;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-view {
  flex: 1;
  padding-top: 2rem;
}

.navbar {
  position: fixed;
  width: 100%;
}

.main-footer {
  width: 100%;
  height: 144px;
  text-align: center;
}

.main-footer.is-primary {
  background-color: $primary;
  color: $primary-invert;
}

.max-width-80 {
  max-width: 80%;
}

.pokestats-brand {
  font-size: 1.5rem;
  margin-left: 1rem;
}

.center {
  margin-left: auto;
}

.close-user {
  //margin-left: 0.9rem;
  color: white;
}
</style>
