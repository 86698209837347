<template lang="pug">
  div
    .modal-card
      header.modal-card-head
        p.modal-card-title Add new pokemon
      section.modal-card-body(ref='cardBody')
        .field
          .label Pokedex number
          .control
            input.input(
              type="text"
              placeholder="003"
              v-model="number"
            )
        .field
          .label Evolution number
          .control
            input.input(
              type="text"
              placeholder="3"
              v-model="evolutionNumber"
            )
        .field
          .label Name
          .control
            input.input(
              type="text"
              placeholder="Venusaur"
              v-model="name"
            )
        .field
          .label Family name
          .control
            input.input(
              type="text"
              placeholder="Bulbasaur"
              v-model="family"
            )
        .field
          .label Generation
          .control
            input.input(
              type="text"
              placeholder="gen1"
              v-model="generation"
            )
        .field
          .label Type 1
          .control
            input.input(
              type="text"
              placeholder="Grass"
              v-model="type1"
            )
        .field
          .label Type 2
          .control
            input.input(
              type="text"
              placeholder="Poison"
              v-model="type2"
            )
        .field
          .label Legendary
          .control
            input.input(
              type="text"
              placeholder="Legendary/Mythical"
              v-model="legendary"
            )
      footer.modal-card-foot
        button.button(type='button', @click='$parent.close()') Close
        button.button.is-primary(@click="savePokemon()") Save
</template>

<script>
import { db } from '@/db';

export default {
  name: 'EditPokemon',
  props: {
    number: {
      default: '',
    },
    evolutionNumber: {
      default: '',
    },
    name: {
      default: '',
    },
    family: {
      default: '',
    },
    generation: {
      default: '',
    },
    type1: {
      default: '',
    },
    type2: {
      default: '',
    },
    legendary: {
      default: '',
    },
    collectable: {
      default: false,
    },
    female: {
      default: false,
    },
    male: {
      default: false,
    },
    lucky: {
      default: false,
    },
    shiny: {
      default: false,
    },
    purified: {
      default: false,
    },
  },
  methods: {
    savePokemon() {
      if (!this.number) return;
      const pokemonRef = db.database.ref(`pokedex/${this.number}`);
      pokemonRef
        .set({
          number: this.number,
          evolutionNumber: this.evolutionNumber,
          name: this.name,
          family: this.family,
          generation: this.generation,
          type1: this.type1,
          type2: this.type2,
          legendary: this.legendary,
          collectable: this.collectable,
          female: this.female,
          male: this.male,
          lucky: this.lucky,
          shiny: this.shiny,
          purified: this.purified,
        })
        .then(() => {
          this.$toast.open({
            message: `${this.name} saved.`,
            type: 'is-black',
            queue: false,
          });
          this.$parent.close();
        })
        .catch(() => {
          this.$toast.open({
            message: 'Failed to update pokemon!',
            type: 'is-danger',
            queue: false,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
