<template lang="pug">
  .container
    .columns.is-hidden-tablet.is-mobile(v-if="showAddFriend")
      .column.has-text-centered
        button.button.is-link.is-medium(@click="addFriend()")
          | Add friend {{ friends[userUid] }}
    h1.title.is-2 Collection
      button.button.is-link.is-medium.is-pulled-right.is-hidden-mobile(v-if="showAddFriend", @click="addFriend()")
        | Add friend
    .columns.is-vcentered.is-multiline
      .column.is-narrow.label-column
          label.label Generations&nbsp
            font-awesome-icon(:icon="['far', 'question-circle']" @click="openGenerationsHelp()")
      .column
        .field.is-grouped.is-grouped-multiline
          .control(v-for='generation in generations', :key='generation.id')
            a(v-if='generation.id', @click='filterGeneration(generation.id)')
              GenerationTag.generation-button(:generationId="generation.id" :class="{ 'is-button-selected': generationFilter === generation.id }")
    .columns.is-vcentered.is-multiline(v-if="userUid")
      .column.is-narrow.label-column
        label.label Collected&nbsp
          font-awesome-icon(:icon="['far', 'question-circle']" @click="openCollectedHelp()")
      .column.is-narrow.collection-filters
        button.button.is-collected(@click="filterCollected('collected')" :class="{ 'is-button-selected': collectedFilters.collected }")
          span.icon
            font-awesome-icon(:icon="['fas', 'check']")
        button.button.is-male(@click="filterCollected('male')" :class="{ 'is-button-selected': collectedFilters.male }")
          span.icon
            font-awesome-icon(:icon="['fas', 'mars']")
        button.button.is-female(@click="filterCollected('female')" :class="{ 'is-button-selected': collectedFilters.female }")
          span.icon
            font-awesome-icon(:icon="['fas', 'venus']")
        button.button.is-lucky(@click="filterCollected('lucky')" :class="{ 'is-button-selected': collectedFilters.lucky }")
          span.icon
            img(src="@/assets/three-leaf-clover.png")
        button.button.is-shiny(@click="filterCollected('shiny')" :class="{ 'is-button-selected': collectedFilters.shiny }")
          span.icon
            img(src="@/assets/shiny-stars.png")
        button.button.is-purified(@click="filterCollected('purified')" :class="{ 'is-button-selected': collectedFilters.purified }")
          span.icon
            img(src="@/assets/purified.png")
        button.button.is-shiny(
          v-for='(tracker, key) in tracked.custom', :key='key'
          :style="{ 'background-color': tracker.color, color: textColor(tracker.color) }"
          @click="filterCollected(key)"
          :class="{ 'is-button-selected': collectedFilters[key] }")
          | {{ tracker.name }}
    .columns.is-vcentered.is-multiline(v-if="userUid")
      .column.is-narrow.label-column
        label.label Uncollected&nbsp
          font-awesome-icon(:icon="['far', 'question-circle']" @click="openUncollectedHelp()")
      .column.is-narrow.collection-filters
        button.button(@click="filterUncollected()" :class="{ 'is-button-selected': uncollectedFilters.collected }")
          span.icon
            font-awesome-icon(:icon="['far', 'square']")
        button.button(@click="filterUncollected('male')" :class="{ 'is-button-selected': uncollectedFilters.male }")
          span.icon
            font-awesome-icon(:icon="['fas', 'mars']")
        button.button(@click="filterUncollected('female')" :class="{ 'is-button-selected': uncollectedFilters.female }")
          span.icon
            font-awesome-icon(:icon="['fas', 'venus']")
        button.button(@click="filterUncollected('lucky')" :class="{ 'is-button-selected': uncollectedFilters.lucky }")
          span.icon
            img(src="@/assets/three-leaf-clover.png")
        button.button(@click="filterUncollected('shiny')" :class="{ 'is-button-selected': uncollectedFilters.shiny }")
          span.icon
            img(src="@/assets/shiny-stars.png")
        button.button(@click="filterUncollected('purified')" :class="{ 'is-button-selected': uncollectedFilters.purified }")
          span.icon
            img(src="@/assets/purified.png")
        button.button(
          v-for='(tracker, key) in tracked.custom', :key='key'
          @click="filterUncollected(key)"
          :class="{ 'is-button-selected': uncollectedFilters[key] }")
          | {{ tracker.name }}
    .columns.is-vcentered.is-multiline
      .column.is-narrow.label-column
          label.label Search&nbsp
            font-awesome-icon(:icon="['far', 'question-circle']" @click="openSearchHelp()")
      .column.is-6.is-4-widescreen
        .field.has-addons
          .control.is-expanded
            input.input(
              type="text"
              placeholder="Search pokedex for..."
              @keydown.enter="$event.target.blur()"
              v-model="filter"
              data-test="search-input"
            )
          .control
            a.button.is-dark(@click="filter=''") Clear
    .columns.is-mobile.is-vcentered.is-variable.is-1
      .column
        b(data-test="matching-pokemons") {{ numberOfFilteredPokemons }} matching pokemons
      .column.is-narrow(v-if='currentFilters.length === 0')
        b-tooltip.display-inline.action-button(:label='`Copy search string`' type="is-dark")
          button.button.is-dark(
            @click="copySearchString()"
          )
            font-awesome-icon(:icon="['fas', 'clipboard-list']")
        b-tooltip.display-inline.action-button(:label='`Select all`' type="is-dark" v-if="!isAllSelected")
          button.button.is-dark(
            @click="selectAll(true)"
            :class="{ 'is-loading': isSelectAllLoading }"
          )
            font-awesome-icon(:icon="['far', 'list-alt']")
        b-tooltip.display-inline.action-button(:label='`Deselect all`' type="is-dark" v-if="isAllSelected")
          button.button(
            @click="selectAll(false)"
          )
            font-awesome-icon(:icon="['far', 'list-alt']")
    .columns.is-mobile.is-vcentered.is-variable.is-1(v-if='currentFilters.length > 0')
      .column
        .field.is-grouped.is-grouped-multiline
          .control(v-for='(filter, index) in currentFilters', :key='index')
            a.tags.has-addons(@click='removeFilter(filter)')
              .tag.is-dark.filter-label(:style="{ backgroundColor: filterColor(filter), color: filterTextColor(filter) }")
                | {{ filter.name }}
              .tag.is-delete
      .column.is-narrow
        b-tooltip.display-inline.action-button(:label='`Copy search string`' type="is-dark")
          button.button.is-dark(
            @click="copySearchString()"
          )
            font-awesome-icon(:icon="['fas', 'clipboard-list']")
        b-tooltip.display-inline.action-button(:label='`Select all`' type="is-dark" v-if="!isAllSelected")
          button.button.is-dark(
            @click="selectAll(true)"
            :class="{ 'is-loading': isSelectAllLoading }"
          )
            font-awesome-icon(:icon="['far', 'list-alt']")
        b-tooltip.display-inline.action-button(:label='`Deselect all`' type="is-dark" v-if="isAllSelected")
          button.button(
            @click="selectAll(false)"
          )
            font-awesome-icon(:icon="['far', 'list-alt']")
    .container.loader.large-loader(v-if="isLoading")
    table.table.is-striped.is-narrow.is-fullwidth.is-hoverable(v-else)
      thead
        tr
          th.is-hidden-mobile.width-generation Generation
          th No
          th Pokemon
          th.is-hidden-mobile(v-if="isLoggedIn")
          th Collection
      tbody
        pokedex-row(
          v-for='pokemon in filteredPokedex', :key='pokemon.number'
          @selected="selectPokemon(pokemon.number, $event)"
          ref="pokedexRow"
        )
          td.is-hidden-mobile(:class="{ 'is-disabled': !pokemon.collectable }")
            a(@click.stop='generationFilter = pokemon.generation')
              GenerationTag(:generationId="pokemon.generation")
          td(:class="{ 'is-disabled': !pokemon.collectable }")
            template(v-if="isLoggedIn")
              b-tooltip.display-inline(:label='wishlistTooltip(pokemon.number)' type="is-dark")
                font-awesome-icon.wishlist.is-hidden-tablet(:icon="['fas', 'star']" @click.stop='setWishlist(pokemon.number, false)' v-if='isOnWishlist(pokemon.number)')
                font-awesome-icon.wishlist.is-hidden-tablet(:icon="['far', 'star']" @click.stop='setWishlist(pokemon.number, true)' v-else)
            span.pokemon-number.is-block-mobile {{ pokemon.number }}
          router-link(tag='td' :to='`/pokemon/${pokemon.number}`' :class="{ 'is-disabled': !pokemon.collectable }" @click.stop='')
            a.pokemon-link
              img.poke-image(:src="`https://db.pokemongohub.net/images/official/detail/${pokemon.number}.png`")
              span.icon-width.is-hidden-tablet
                b-tooltip.display-inline(:label='`Regional: ${pokemon.regional}`' type="is-dark" :active="tooltipActive" v-if="pokemon.regional")
                  font-awesome-icon.icon-width(:icon="['fas', 'globe-americas']" @click.stop="tooltipActive = !tooltipActive")
              div.pokemon-name(:class="{ 'has-text-grey': !pokemon.collectable }")
                | {{ pokemon.name }}
              span.icon-width.is-hidden-mobile
                b-tooltip.display-inline(:label='`Regional: ${pokemon.regional}`' type="is-dark" v-if="pokemon.regional")
                  font-awesome-icon(:icon="['fas', 'globe-americas']")
          td.is-hidden-mobile(v-if="isLoggedIn" :class="{ 'is-disabled': !pokemon.collectable }")
            b-tooltip.display-inline(:label='wishlistTooltip(pokemon.number)' type="is-dark")
              font-awesome-icon.wishlist(
                :icon="['fas', 'star']"
                @click.stop='setWishlist(pokemon.number, false)'
                v-if='isOnWishlist(pokemon.number)'
              )
              font-awesome-icon.wishlist(
                :icon="['far', 'star']"
                @click.stop='setWishlist(pokemon.number, true)'
                v-else
              )
          td(v-if="!userUid")
            router-link(to='/login' @click.stop) Login
            span  to update your personal collection
          td(v-else)
            .collection-buttons
              button.button.collection-button.is-small(
                v-if="tracked.collected"
                :class="{ 'is-dark': isCollected(pokemon.number) }",
                :disabled="!pokemon.collectable",
                @click.stop="toggleCollected(pokemon.number)"
              )
                font-awesome-icon(:icon="['fas', 'check']", v-if="isCollected(pokemon.number)")
                font-awesome-icon(:icon="['far', 'square']", v-else)
              button.button.collection-button.is-small(
                v-if="tracked.male",
                :class="{ 'is-male': isCollected(pokemon.number, 'male') }",
                :disabled="!pokemon.collectable || !pokemon.male",
                @click.stop="toggleCollected(pokemon.number, 'male')"
              )
                font-awesome-icon(:icon="['fas', 'mars']")
              button.button.collection-button.is-small(
                v-if="tracked.female",
                :class="{ 'is-female': isCollected(pokemon.number, 'female') }",
                :disabled="!pokemon.collectable || !pokemon.female",
                @click.stop="toggleCollected(pokemon.number, 'female')"
              )
                font-awesome-icon(:icon="['fas', 'venus']")
              button.button.collection-button.is-small(
                v-if="tracked.lucky"
                :class="{ 'is-lucky': isCollected(pokemon.number, 'lucky') }",
                :disabled="!pokemon.collectable || !pokemon.lucky",
                @click.stop="toggleCollected(pokemon.number, 'lucky')"
              )
                img.collection-image(src="@/assets/three-leaf-clover.png")
              button.button.collection-button.is-small(
                v-if="tracked.shiny",
                :class="{ 'is-shiny': isCollected(pokemon.number, 'shiny') }",
                :disabled="!pokemon.collectable || !pokemon.shiny",
                @click.stop="toggleCollected(pokemon.number, 'shiny')"
              )
                img.collection-image(src="@/assets/shiny-stars.png")
              button.button.collection-button.is-small(
                v-if="tracked.purified",
                :class="{ 'is-purified': isCollected(pokemon.number, 'purified') }",
                :disabled="!pokemon.collectable || !pokemon.purified",
                @click.stop="toggleCollected(pokemon.number, 'purified')"
              )
                img.collection-image(src="@/assets/purified.png")
              button.button.collection-button.is-small(
                v-for='(tracker, key) in tracked.custom', :key='key'
                :style="collectionButtonStyle(pokemon.number, key, tracker.color)"
                :disabled="!pokemon.collectable",
                @click.stop="toggleCollected(pokemon.number, key)"
              )
                span {{ tracker.name }}
</template>

<script>
import { db } from '@/db';
import PokedexRow from '@/components/PokedexRow';
import AddFriend from '@/components/AddFriend';
import SearchHelp from './help-modals/SearchHelp';
import UncollectedHelp from './help-modals/UncollectedHelp';
import CollectedHelp from './help-modals/CollectedHelp';
import GenerationsHelp from './help-modals/GenerationsHelp';
import GenerationTag from '@/components/common/GenerationTag';
import { isLight, collectionTypeColors } from '@/utils/color-utils.js';
import { copyTextToClipboard } from '@/utils/html-utils.js';
import { sortByPokedexNumber, isPokeNumber, isGenerationId } from '@/utils/common.js';

const filterTypes = {
  GENERATION: 'generation',
  COLLECTED: 'collected',
  UNCOLLECTED: 'uncollected',
};

export default {
  name: 'Collection',
  components: {
    PokedexRow,
    GenerationTag,
  },
  data() {
    return {
      userUid: undefined,
      isLoading: true,
      tooltipActive: false,
      isSelectAllLoading: false,
      isAllSelected: false,
      showConfirm: false,
      filter: '',
      generationFilter: undefined,
      collectedFilters: {},
      uncollectedFilters: {},
      selectedPokemons: {},
      filteredPokedex: [],
      collectablePokedex: [],
      pokedex: null,
      collection: null,
      tracked: {},
      generations: null,
      username: null,
      friends: null,
    };
  },
  async created() {
    this.userUid = this.$route.params.uid || localStorage.getItem('userUid');
    this.filter = this.$route.query.filter || '';
    this.generationFilter = this.$route.query.generation;
    if (this.$route.query.collected) {
      this.collectedFilters = this.$route.query.collected.split(',').reduce((o, filter) => {
        o[filter] = true;
        return o;
      }, {});
    }
    if (this.$route.query.uncollected) {
      this.uncollectedFilters = this.$route.query.uncollected.split(',').reduce((o, filter) => {
        o[filter] = true;
        return o;
      }, {});
    }

    this.generations = JSON.parse(localStorage.getItem('generationsCache'));
    this.tracked = JSON.parse(localStorage.getItem('trackedCache'));

    this.$bind('collection', db.database.ref('users/' + this.userUid + '/pokemons'));

    [this.pokedex, this.generations, this.tracked, this.settings, this.username, this.friends] = await Promise.all([
      db.pokedex(),
      db.generations(),
      db.tracked(this.userUid),
      db.settings(this.userUid),
      db.username(this.userUid),
      db.friends(localStorage.getItem('userUid')),
    ]);

    localStorage.setItem('generationsCache', JSON.stringify(this.generations));
    localStorage.setItem('trackedCache', JSON.stringify(this.tracked));

    this.collectablePokedex = Object.keys(this.pokedex)
      .sort(sortByPokedexNumber)
      .map(key => this.pokedex[key])
      .filter(pokemon => pokemon.collectable);
    this.filteredPokedex = this.collectablePokedex;
    this.isLoading = false;
    this.applyFilters();
  },
  watch: {
    filter() {
      this.applyFilters();
      this.$router.replace({
        path: this.$route.path,
        query: { ...this.$route.query, filter: this.filter ? this.filter : undefined },
      });
    },
    generationFilter() {
      this.applyFilters();
      this.$router.replace({
        path: this.$route.path,
        query: { ...this.$route.query, generation: this.generationFilter },
      });
    },
    collectedFilters() {
      this.applyFilters();
      const filters = Object.keys(this.collectedFilters).join(',');
      this.$router.replace({
        path: this.$route.path,
        query: { ...this.$route.query, collected: filters ? filters : undefined },
      });
    },
    uncollectedFilters() {
      this.applyFilters();
      const filters = Object.keys(this.uncollectedFilters).join(',');
      this.$router.replace({
        path: this.$route.path,
        query: { ...this.$route.query, uncollected: filters ? filters : undefined },
      });
    },
  },
  computed: {
    pokedexName() {
      if (this.$route.params.uid) {
        if (this.username['.value']) {
          return `${this.username['.value']}'s`;
        } else if (!this.isLoading) {
          return 'Anonymous';
        }
      } else if (this.isLoggedIn) {
        return 'Your';
      }
      return '';
    },
    showAddFriend() {
      return this.$route.params.uid && this.isLoggedIn && this.friends != null && !this.friends[this.userUid];
    },
    currentFilters() {
      const filters = [];
      if (this.generationFilter && this.generations) {
        const filterObject = {
          id: this.generationFilter,
          name: this.generations[this.generationFilter].name,
          type: filterTypes.GENERATION,
        };
        filters.push(filterObject);
      }
      for (let filter of Object.keys(this.collectedFilters)) {
        filters.push({
          id: filter,
          name: this.filterName(filter),
          type: filterTypes.COLLECTED,
        });
      }
      for (let filter of Object.keys(this.uncollectedFilters)) {
        const filterName = filter === 'collected' ? 'uncollected' : this.filterName(filter);
        filters.push({
          id: filter,
          name: filterName,
          type: filterTypes.UNCOLLECTED,
        });
      }
      return filters;
    },
    numberOfFilteredPokemons() {
      return Object.keys(this.filteredPokedex).length;
    },
    isLoggedIn() {
      return Boolean(localStorage.getItem('userUid'));
    },
  },
  methods: {
    applyFilters() {
      const filter = this.filter.trim();
      if (isPokeNumber(filter)) {
        this.filteredPokedex = this.collectablePokedex.filter(pokemon => Number(pokemon.number) === Number(filter));
      } else if (isGenerationId(filter.toLowerCase())) {
        this.filteredPokedex = this.collectablePokedex.filter(pokemon => pokemon.generation === filter.toLowerCase());
      } else if (filter.toLowerCase() === 'regional') {
        this.filteredPokedex = this.collectablePokedex.filter(pokemon => pokemon.regional);
      } else if (filter.toLowerCase() === 'shiny') {
        this.filteredPokedex = this.collectablePokedex.filter(pokemon => pokemon.shiny);
      } else if (filter.toLowerCase() === 'purified') {
        this.filteredPokedex = this.collectablePokedex.filter(pokemon => pokemon.purified);
      } else if (filter.toLowerCase() === 'shadow') {
        this.filteredPokedex = this.collectablePokedex.filter(pokemon => pokemon.purified);
      } else if (filter.toLowerCase() === 'wishlist' || filter.toLowerCase() === 'starred') {
        this.filteredPokedex = this.collectablePokedex.filter(pokemon => this.isCollected(pokemon.number, 'wishlist'));
      } else if (filter.toLowerCase() === 'legendary') {
        this.filteredPokedex = this.collectablePokedex.filter(
          pokemon => pokemon.legendary && pokemon.legendary.toLowerCase() === 'legendary'
        );
      } else if (filter.toLowerCase() === 'mythical') {
        this.filteredPokedex = this.collectablePokedex.filter(
          pokemon => pokemon.legendary && pokemon.legendary.toLowerCase() === 'mythical'
        );
      } else if (filter.startsWith('+')) {
        const trimmedFilter = filter.substring(1).trim().toLowerCase();
        const filteredByName = this.collectablePokedex.filter(pokemon =>
          pokemon.name.toLowerCase().startsWith(trimmedFilter)
        );
        const familyNames = filteredByName.map(pokemon => pokemon.family);
        this.filteredPokedex = this.collectablePokedex.filter(pokemon =>
          familyNames.find(familyName => familyName.toLowerCase() === pokemon.family.toLowerCase())
        );
      } else {
        this.filteredPokedex = this.collectablePokedex.filter(pokemon =>
          pokemon.name.toLowerCase().startsWith(filter.toLowerCase())
        );
      }

      if (this.generationFilter) {
        this.filteredPokedex = this.filteredPokedex.filter(pokemon => pokemon.generation === this.generationFilter);
      }

      if (this.collectedFilters && Object.keys(this.collectedFilters).length > 0) {
        for (let filter of Object.keys(this.collectedFilters)) {
          this.filteredPokedex = this.filteredPokedex.filter(pokemon => this.isCollected(pokemon.number, filter));
        }
      }

      if (this.uncollectedFilters && Object.keys(this.uncollectedFilters).length > 0) {
        for (let filter of Object.keys(this.uncollectedFilters)) {
          this.filteredPokedex = this.filteredPokedex.filter(
            pokemon => this.collectable(pokemon, filter) && !this.isCollected(pokemon.number, filter)
          );
        }
      }
    },
    removeFilter(filter) {
      if (filter.type === filterTypes.GENERATION) {
        this.generationFilter = undefined;
      }
      if (filter.type === filterTypes.COLLECTED) {
        this.$delete(this.collectedFilters, filter.id);
      }
      if (filter.type === filterTypes.UNCOLLECTED) {
        this.$delete(this.uncollectedFilters, filter.id);
      }
    },
    filterCollected(collectedType = 'collected') {
      if (this.collectedFilters[collectedType]) {
        this.$delete(this.collectedFilters, collectedType);
      } else {
        this.$set(this.collectedFilters, collectedType, true);
      }
    },
    filterUncollected(collectedType = 'collected') {
      if (this.uncollectedFilters[collectedType]) {
        this.$delete(this.uncollectedFilters, collectedType);
      } else {
        this.$set(this.uncollectedFilters, collectedType, true);
      }
    },
    filterGeneration(generationId) {
      if (this.generationFilter === generationId) {
        this.generationFilter = undefined;
      } else {
        this.generationFilter = generationId;
      }
    },
    selectPokemon(pokeNumber, selected) {
      if (selected) {
        this.selectedPokemons[pokeNumber] = selected;
      } else {
        delete this.selectedPokemons[pokeNumber];
      }
    },
    selectAll(select) {
      if (this.isLoading) {
        return;
      }
      if (select) {
        // Make sure all rows have been loaded and show select loader
        this.isSelectAllLoading = true;
        setTimeout(() => {
          this.$nextTick(() => {
            this.$refs.pokedexRow.forEach(row => row.setSelected(true));
            this.isSelectAllLoading = false;
            this.isAllSelected = true;
          });
        }, 10);
      } else {
        this.$refs.pokedexRow.forEach(row => row.setSelected(false));
        this.isAllSelected = false;
      }
    },
    showRegional(pokeNumber) {
      this.$modal.open({
        content: `<div class="notification"><b>Regional: </b>${this.pokedex[pokeNumber].regional}</div>`,
        width: 300,
      });
    },
    isOnWishlist(pokeNumber) {
      return this.collection[pokeNumber] && this.collection[pokeNumber].wishlist;
    },
    wishlistTooltip(pokeNumber) {
      const pokeName = this.pokedex[pokeNumber].name;
      return this.isOnWishlist(pokeNumber) ? `Remove ${pokeName} from wishlist` : `Add ${pokeName} to wishlist`;
    },
    setWishlist(pokeNumber, status) {
      if (!this.$route.params.uid) {
        const wishlistRef = db.database.ref(`users/${this.userUid}/pokemons/${pokeNumber}/wishlist`);
        wishlistRef
          .set(status)
          .then(() => {
            this.$toast.open({
              message: status
                ? `${this.pokedex[pokeNumber].name} added to wishlist.`
                : `${this.pokedex[pokeNumber].name} removed from wishlist.`,
              type: 'is-black',
              queue: false,
            });
          })
          .catch(() => {
            this.$toast.open({
              message: 'Failed to update!',
              type: 'is-danger',
              queue: false,
            });
          });
      }
    },
    collectionButtonStyle(pokeNumber, trackerKey, trackerColor) {
      if (this.isCollected(pokeNumber, trackerKey)) {
        return { 'background-color': trackerColor, color: this.textColor(trackerColor) };
      }
      return '';
    },
    toggleCollected(pokeNumber, collectedType = 'collected') {
      if (!this.$route.params.uid) {
        if (this.selectedPokemons[pokeNumber] && Object.keys(this.selectedPokemons).length > 1) {
          this.setAllSelectedCollected(collectedType, !this.isCollected(pokeNumber, collectedType));
        } else {
          this.setCollected(pokeNumber, collectedType, !this.isCollected(pokeNumber, collectedType))
            .then(() => {
              this.$toast.open({
                message: `${this.pokedex[pokeNumber].name} updated.`,
                type: 'is-black',
                queue: false,
              });
            })
            .catch(error => {
              console.log('Failed to update: ', error);
              this.$toast.open({
                message: 'Failed to update!',
                type: 'is-danger',
                queue: false,
              });
            });
        }
      }
    },
    setCollected(pokeNumber, collectedType, status) {
      const collectedRef = db.database.ref(`users/${this.userUid}/pokemons/${pokeNumber}/${collectedType}`);
      return collectedRef.set(status);
    },
    isCollected(pokeNumber, collectedType = 'collected') {
      return this.collection[pokeNumber] && this.collection[pokeNumber][collectedType];
    },
    setAllSelectedCollected(collectedType = 'collected', collected) {
      this.$dialog.confirm({
        title: 'Update all selected',
        message: `Are you sure you want update <b>${Object.keys(this.selectedPokemons).length}</b> selected rows?`,
        type: 'is-dark',
        confirmText: 'Yes',
        //scroll: 'keep', Makes scroll flip out if scrolled far down on page
        onConfirm: () => {
          for (const pokeNumber in this.selectedPokemons) {
            const pokemon = this.pokedex[pokeNumber];
            if (pokemon && this.collectable(pokemon, collectedType)) {
              this.setCollected(pokeNumber, collectedType, collected);
            }
          }
          this.$toast.open(`Updated ${Object.keys(this.selectedPokemons).length} rows`);
        },
      });
    },
    collectable(pokemon, collectedType) {
      if (!pokemon.collectable) {
        return false;
      }
      if (collectedType === 'shiny') {
        return pokemon.shiny;
      }
      if (collectedType === 'male') {
        return pokemon.male;
      }
      if (collectedType === 'female') {
        return pokemon.female;
      }
      if (collectedType === 'purified') {
        return pokemon.purified;
      }
      if (collectedType === 'lucky') {
        return pokemon.lucky;
      }
      return true;
    },
    generationColor(generationId) {
      if (this.generations[generationId] && this.generations[generationId].color) {
        return this.generations[generationId].color;
      }
      return 'grey';
    },
    filterColor(filter) {
      if (filter.type === filterTypes.GENERATION) {
        return this.generationColor(filter.id);
      }
      if (filter.type === filterTypes.COLLECTED) {
        if (collectionTypeColors[filter.name]) {
          return collectionTypeColors[filter.name];
        }
        return '#222222'; //Dark
      }
      if (filter.type === filterTypes.UNCOLLECTED) {
        return '#FFFFFF'; //White
      }
    },
    filterTextColor(filter) {
      return this.textColor(this.filterColor(filter));
    },
    filterName(filterId) {
      return (this.tracked.custom && this.tracked.custom[filterId] && this.tracked.custom[filterId].name) || filterId;
    },
    textColor(backgroundColor) {
      return isLight(backgroundColor) ? 'rgb(54, 54, 54)' : 'rgb(255, 255, 255)';
    },
    copySearchString() {
      const searchString = this.filteredPokedex
        .map(pokemon => {
          return Number(pokemon.number);
        })
        .join(',');
      // const searchString = this.filteredPokedex
      //   .reduce(sString, pokemon => {
      //     if (sString === '') {
      //       return sString + Number(pokemon.number);
      //     } else if()
      //   }, '')
      //   .join(',');
      if (copyTextToClipboard(searchString)) {
        this.$toast.open(`Search string copied to clipboard.`);
      } else {
        this.$toast.open(`Failed to copy search string to clipboard.`);
      }
    },
    addFriend() {
      this.$modal.open({
        parent: this,
        component: AddFriend,
        width: 600,
        props: {
          userIdInput: this.userUid,
        },
      });
    },
    openSearchHelp() {
      this.$modal.open({
        parent: this,
        component: SearchHelp,
        width: 600,
      });
    },
    openUncollectedHelp() {
      this.$modal.open({
        parent: this,
        component: UncollectedHelp,
        width: 400,
      });
    },
    openCollectedHelp() {
      this.$modal.open({
        parent: this,
        component: CollectedHelp,
        width: 400,
      });
    },
    openGenerationsHelp() {
      this.$modal.open({
        parent: this,
        component: GenerationsHelp,
        width: 400,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.label-column {
  width: 130px;
  padding-right: 0;
  @media only screen and (max-width: 768px) {
    padding-bottom: 0;
  }
}

.collection-filters {
  padding-top: 0;
  padding-bottom: 0;

  button {
    font-weight: 600;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-right: 0.5em;
  }
}

.select-all-button {
  @media only screen and (min-width: 768px) {
    float: right !important;
  }
}

.filter-label {
  border: 1px solid #e4e4e4;
}

.width-generation {
  width: 170px;
}

.table td {
  vertical-align: middle;
  padding-top: 0;
  padding-bottom: 0;
}

.poke-image {
  vertical-align: middle;
  height: 42px;
  padding-right: 4px;
  transition: height 0.15s;

  &:hover {
    height: 128px;
  }
  @media (max-width: 768px) {
    transition: height 0.3s;
  }
  @media (max-width: 366px) {
    height: 84px;
  }
}

.pokemon-name {
  width: 100px;
  font-weight: 600;
  display: inline-block;
}

.wishlist {
  margin: auto;
  display: block;
  cursor: pointer;
}

.pokemon-number {
  width: 28px;
  margin: auto;
}

@media (min-width: 367px) {
  .icon-width {
    display: inline-block;
    height: 20px;
    width: 20px;
  }
}

.action-button {
  margin-left: 0.5em;
}

@media (any-hover: none) {
  .action-button.b-tooltip:after,
  .action-button.b-tooltip:before {
    display: none;
  }
}

.pokemon-link:hover {
  .pokemon-name {
    text-decoration: underline;
    color: black;
  }
}
.pokemon-name {
  color: rgb(54, 54, 54);
}
</style>
