<template lang="pug">
   .container
    h1.title.is-2 Wishlist
    h2.title.is-5(v-if="!isLoggedIn")
      router-link(to='/login') Login
      | to view your wishlist
    template(v-else)
      .container.loader.large-loader(v-if="isLoading")
      .template(v-else)
        h2.title.is-5(v-if="wishlist.length <= 0")
          span(v-if="$route.params.uid") No pokemons added to wishlist
          span(v-else) Add pokemons to your your wishlist by pressing the star in your pokedex
        .columns.is-multiline.is-mobile
          .column.is-narrow(v-for='pokemon in wishlist', :key='pokemon.number')
            .card
              .card-image
                a.remove-button(@click="setWishlist(pokemon.number, false)")
                  font-awesome-icon(:icon="['far', 'times-circle']" size="lg" fixed-width)
                figure.image.pokemon-image-fullsize
                  img(:src="`https://db.pokemongohub.net/images/official/detail/${pokemon.number}.png`" :alt='pokemon.name')
              .card-content
                .media
                  .media-left.pokemon-number-card(:style="{ backgroundColor: generationColor(pokemon.generation) }")
                    | {{ pokemon.number }}
                  .media-content
                    p.title.is-4.pokemon-name-card {{ pokemon.name }}
                    p.subtitle.is-6
                      a.tags.has-addons.generation-tag
                        span.tag.is-dark {{ generationNumber(pokemon.generation) }}
                        span.tag.has-text-white(:style="{ backgroundColor: generationColor(pokemon.generation) }")
                          | {{ generationName(pokemon.generation) }}
                .content
                  textarea.textarea.has-fixed-size.comment-textarea(
                    placeholder="Enter comment (optional)"
                    maxlength="100"
                    v-model="comments[pokemon.number]"
                    @blur="saveComment(pokemon.number)"
                    @input="debounceSaveComment(pokemon.number)"
                  )
        //table.table.is-striped.is-narrow.is-fullwidth.is-hoverable
          thead
            tr
              //th.is-hidden-mobile.width-generation Generation
              th No
              th Pokemon
          tbody
            pokedex-row(
              v-for='pokemon in wishlist', :key='pokemon.number'
            )
              //td
                a.tags.has-addons.generation-tag(@click.stop='generationFilter = pokemon.generation')
                  span.tag.is-dark {{ generationNumber(pokemon.generation) }}
                  span.tag.has-text-white(:style="{ backgroundColor: generationColor(pokemon.generation) }")
                    | {{ generationName(pokemon.generation) }}
              td
                span.pokemon-number.is-block-mobile {{ pokemon.number }}
              td(:class="{ 'is-disabled': !pokemon.collectable }")
                img.pokemon-image(:src="`https://db.pokemongohub.net/images/official/detail/${pokemon.number}.png`")
                .pokemon-name(:class="{ 'has-text-grey': !pokemon.collectable }")
                  | {{ pokemon.name }}
</template>

<script>
import { db } from '@/db';
import _ from 'lodash';
import PokedexRow from '@/components/PokedexRow';

export default {
  name: 'Wishlist',
  components: {
    PokedexRow,
  },
  data() {
    return {
      isLoading: true,
      failedToSaveComment: false,
      comments: {},
      collection: null,
      pokedex: null,
      generations: null,
    };
  },
  async created() {
    this.userUid = this.$route.params.uid || localStorage.getItem('userUid');

    this.$bind('collection', db.database.ref('users/' + this.userUid + '/pokemons'));

    this.pokedex = await db.pokedex();
    this.generations = await db.generations();

    this.isLoading = false;
    for (const [pokeNumber, pokemon] of Object.entries(this.collection)) {
      this.comments[pokeNumber] = pokemon.wishlistComment;
    }
  },
  computed: {
    isLoggedIn() {
      return Boolean(localStorage.getItem('userUid'));
    },
    wishlist() {
      return Object.values(this.pokedex)
        .filter(pokemon => this.collection[pokemon.number] && this.collection[pokemon.number].wishlist)
        .sort((pokemon1, pokemon2) => pokemon1.number - pokemon2.number);
    },
  },
  methods: {
    generationName(generationId) {
      if (this.generations[generationId]) {
        return this.generations[generationId].name;
      }
      return '';
    },
    generationNumber(generationId) {
      if (this.generations[generationId]) {
        return this.generations[generationId].number;
      }
      return '';
    },
    generationColor(generationId) {
      if (this.generations[generationId] && this.generations[generationId].color) {
        return this.generations[generationId].color;
      }
      return 'grey';
    },
    setWishlist(pokeNumber, status) {
      this.$firebaseRefs.collection
        .child(pokeNumber)
        .child('wishlist')
        .set(status)
        .then(() => {
          this.$toast.open({
            message: status
              ? `${this.pokedex[pokeNumber].name} added to wishlist.`
              : `${this.pokedex[pokeNumber].name} removed from wishlist.`,
            type: 'is-black',
            queue: false,
          });
        })
        .catch(error => {
          console.error(error);
          this.$toast.open({
            message: 'Failed to update!',
            type: 'is-danger',
            queue: false,
          });
        });
    },
    saveComment(pokeNumber) {
      if (this.comments[pokeNumber] !== undefined) {
        this.$firebaseRefs.collection
          .child(pokeNumber)
          .child('wishlistComment')
          .set(this.comments[pokeNumber])
          .then(() => {
            if (this.failedToSaveComment) {
              this.$toast.open({
                message: 'Comment saved.',
                queue: false,
              });
              this.failedToSaveComment = false;
            }
          })
          .catch(() => {
            this.failedToSaveComment = true;
            this.$toast.open({
              message: 'Failed to save comment!',
              queue: false,
              type: 'is-danger',
            });
          });
      }
    },
    debounceSaveComment: _.debounce(function (pokeNumber) {
      this.saveComment(pokeNumber);
    }, 500),
  },
};
</script>

<style lang="scss" scoped>
.table td {
  vertical-align: middle;
  padding-top: 0;
  padding-bottom: 0;
}

.remove-button {
  position: absolute;
  right: 0;
  width: 2em;
  padding-top: 0.5em;
  padding-right: 0.5em;
  color: #363636;
}

.pokemon-image {
  vertical-align: middle;
  height: 60px;
  padding-right: 4px;
}

.pokemon-image-fullsize {
  width: 215px;
  height: 215px;
  margin-left: auto;
  margin-right: auto;
}

.pokemon-number-card {
  font-size: x-large;
  font-weight: 600;
  background-color: #4a4a4a;
  color: white;
  height: 60px;
  width: 60px;
  border-radius: 4px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pokemon-name {
  width: 100px;
  font-weight: 600;
  display: inline-block;
}

.pokemon-name-card {
  word-break: keep-all;
  width: 150px;
}

.comment-textarea {
  min-height: 50px;
  border: none;
  box-shadow: none;
  padding: 0;
  font-style: italic;
}
</style>
