<template lang="pug">
  .container
    h1.title.is-2 Admin log
    .container.loader.large-loader(v-if="isLoading")
    table.table.is-striped.is-narrow.is-fullwidth.is-hoverable(v-else)
      thead
        tr
          th.is-hidden-mobile Generation
          th No
          th Pokemon
          th Timestamp
          th User
      tbody
        tr(v-for="log in sortedAdminLog")
          td.is-hidden-mobile
            GenerationTag(:generationId="pokedex[log.pokemonNumber] && pokedex[log.pokemonNumber].generation")
          td {{ log.pokemonNumber }}
          td
            img.poke-image(:src="`https://db.pokemongohub.net/images/official/detail/${log.pokemonNumber}.png`")
            .pokemon-name
              | {{ pokedex[log.pokemonNumber] && pokedex[log.pokemonNumber].name }}
          td {{ (new Date(log.timestamp)).toLocaleString('sv-SE') }}
          td {{ log.user.name }}

</template>

<script>
import { db } from '@/db';
import GenerationTag from '@/components/common/GenerationTag';

export default {
  name: 'AdminLog',
  components: {
    GenerationTag,
  },
  data() {
    return {
      pokedex: null,
      generations: null,
      adminLog: null,
      isLoading: true,
    };
  },
  async created() {
    [this.pokedex, this.generations, this.adminLog] = await Promise.all([
      db.pokedex(),
      db.generations(),
      db.adminlog(),
    ]);
    this.isLoading = false;
  },
  computed: {
    sortedAdminLog() {
      return Object.keys(this.adminLog)
        .map(key => this.adminLog[key])
        .sort((a, b) => {
          const aTimestamp = a.timestamp ? Date.parse(a.timestamp) : 0;
          const bTimestamp = b.timestamp ? Date.parse(b.timestamp) : 0;
          return bTimestamp - aTimestamp;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.table td {
  vertical-align: middle;
  padding-top: 0;
  padding-bottom: 0;
}

.poke-image {
  vertical-align: middle;
  height: 42px;
  padding-right: 4px;
}

.pokemon-name {
  width: 100px;
  font-weight: 600;
  display: inline-block;
}

.pokemon-number {
  width: 28px;
  margin: auto;
}
</style>
