export function pad(number, width = 3, character = '0') {
  number = number + '';
  return number.length >= width ? number : new Array(width - number.length + 1).join(character) + number;
}

export const sortByPokedexNumber = (p1, p2) => pad(p1, 4) - pad(p2, 4);

export function isPokeNumber(str) {
  return /^[0-9][0-9]?[0-9]?[0-9]?$/.test(str);
}

export function isGenerationId(str) {
  return /^gen[0-9]$/.test(str);
}
