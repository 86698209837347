<template lang="pug">
  .container.loader.large-loader(v-if="isLoading")
  .container(v-else)
    .columns
      .column.is-2.is-flex.is-align-items-end.is-hidden-mobile
        router-link(:to='`/pokemon/${prevPokeNumber}`' @click.stop)
          button.button.next-button
            font-awesome-icon.next-icon.mr-4(:icon="['fas', 'chevron-left']")
            img.next-poke-image(:src="`https://db.pokemongohub.net/images/official/detail/${prevPokeNumber}.png`" :alt='pokemon.name')
      .column.is-8
        h1.title.is-1 {{ pokemon.name }}
        .box
          .columns
            .column.is-5
              template(v-if="isLoggedIn")
                span.is-pulled-right
                  b-tooltip.display-inline(:label='wishlistTooltip' type="is-dark")
                    font-awesome-icon.wishlist(:icon="['fas', 'star']" @click.stop='setWishlist(false)' v-if='isOnWishlist')
                    font-awesome-icon.wishlist(:icon="['far', 'star']" @click.stop='setWishlist(true)' v-else)
              .is-flex.is-align-items-center.is-flex-direction-column
                img(:src="`https://db.pokemongohub.net/images/official/detail/${pokeNumber}.png`" :alt='pokemon.name')
              table.table.is-fullwidth
                tr
                  td
                    label.label Number:
                  td
                    | {{ pokemon.number }}
                tr
                  td
                    label.label Type:
                  td
                    | {{ pokemon.type1 }}
                tr
                  td
                    label.label Family:
                  td
                    | {{ pokemon.family }}
                tr
                  td
                    label.label Generation:
                  td
                    a.tags.has-addons.generation-tag(@click.stop='generationFilter = pokemon.generation')
                      span.tag.is-dark {{ generationNumber(pokemon.generation) }}
                      span.tag.has-text-white(:style="{ backgroundColor: generationColor(pokemon.generation) }")
                        | {{ generationName(pokemon.generation) }}
            .column.is-2
            .column.is-5.is-vcentered(v-if="!isLoggedIn")
              router-link(to='/login' @click.stop) Login
              span  to update your personal collection.
            .column.is-5.is-flex.is-align-items-end(v-else)
              table.table.is-fullwidth
                tr
                  td
                    label.label Collected:
                  td
                    .collection-buttons
                      button.button.collection-button.is-small(
                        :class="{ 'is-dark': isCollected() }",
                        @click.stop="toggleCollected()",
                        :disabled="!pokemon.collectable",
                      )
                        font-awesome-icon(:icon="['fas', 'check']", v-if="isCollected()")
                        font-awesome-icon(:icon="['far', 'square']", v-else)
                tr
                  td
                    label.label(:class="{ 'is-disabled': !pokemon.male }") Male:
                  td
                    .collection-buttons
                      button.button.collection-button.is-small(
                        :class="{ 'is-male': isCollected('male') }",
                        @click.stop="toggleCollected('male')",
                        :disabled="!pokemon.collectable || !pokemon.male"
                      )
                        font-awesome-icon(:icon="['fas', 'check']", v-if="isCollected('male')")
                        font-awesome-icon(:icon="['far', 'square']", v-else)
                tr
                  td
                    label.label(:class="{ 'is-disabled': !pokemon.female }") Female:
                  td
                    .collection-buttons
                      button.button.collection-button.is-small(
                        :class="{ 'is-female': isCollected('female') }",
                        @click.stop="toggleCollected('female')"
                        :disabled="!pokemon.collectable || !pokemon.female"
                      )
                        font-awesome-icon(:icon="['fas', 'check']", v-if="isCollected('female')")
                        font-awesome-icon(:icon="['far', 'square']", v-else)
                tr
                  td
                    label.label(:class="{ 'is-disabled': !pokemon.lucky }") Lucky:
                  td
                    .collection-buttons
                      button.button.collection-button.is-small(
                        :class="{ 'is-lucky': isCollected('lucky') }",
                        @click.stop="toggleCollected('lucky')",
                        :disabled="!pokemon.collectable || !pokemon.lucky"
                      )
                        font-awesome-icon(:icon="['fas', 'check']", v-if="isCollected('lucky')")
                        font-awesome-icon(:icon="['far', 'square']", v-else)
                tr
                  td
                    label.label(:class="{ 'is-disabled': !pokemon.shiny }") Shiny:
                  td
                    .collection-buttons
                      button.button.collection-button.is-small(
                        :class="{ 'is-shiny': isCollected('shiny') }",
                        @click.stop="toggleCollected('shiny')",
                        :disabled="!pokemon.collectable || !pokemon.shiny"
                      )
                        font-awesome-icon(:icon="['fas', 'check']", v-if="isCollected('shiny')")
                        font-awesome-icon(:icon="['far', 'square']", v-else)
                tr
                  td
                    label.label(:class="{ 'is-disabled': !pokemon.purified }") Purified:
                  td
                    .collection-buttons
                      button.button.collection-button.is-small(
                        :class="{ 'is-purified': isCollected('purified') }",
                        @click.stop="toggleCollected('purified')"
                        :disabled="!pokemon.collectable || !pokemon.purified"
                      )
                        font-awesome-icon(:icon="['fas', 'check']", v-if="isCollected('purified')")
                        font-awesome-icon(:icon="['far', 'square']", v-else)
                tr(v-for='(tracker, key) in tracked.custom', :key='key')
                  td
                    label.label {{ tracker.name }}:
                  td
                    .collection-buttons
                      button.button.collection-button.is-small(
                        :style="collectionButtonStyle(key, tracker.color)"
                        @click.stop="toggleCollected(key)"
                      )
                        font-awesome-icon(:icon="['fas', 'check']", v-if="isCollected(key)")
                        font-awesome-icon(:icon="['far', 'square']", v-else)
      .column.is-flex.is-align-items-end.is-justify-content-end
        router-link.is-hidden-tablet(:to='`/pokemon/${prevPokeNumber}`' @click.stop)
          button.button.next-button
            font-awesome-icon.next-icon.mr-4(:icon="['fas', 'chevron-left']")
            img.next-poke-image(:src="`https://db.pokemongohub.net/images/official/detail/${prevPokeNumber}.png`" :alt='pokemon.name')
        router-link(:to='`/pokemon/${nextPokeNumber}`' @click.stop)
          button.button.next-button
            img.next-poke-image(:src="`https://db.pokemongohub.net/images/official/detail/${nextPokeNumber}.png`" :alt='pokemon.name')
            font-awesome-icon.next-icon.ml-4(:icon="['fas', 'chevron-right']")
    hr
    .columns(v-if="isLoggedIn")
      .column
        h2.title.is-3 Friend Collections
      .column.is-narrow.is-flex.is-justify-content-end
        label.label Show custom trackers
          button.button.is-small.ps-checkbox.ml-2.mr-1(
            @click.prevent="toggleShowCustomTrackers",
          )
            font-awesome-icon(:icon="['fas', 'check']", v-if="showCustomTrackers")
    .columns.is-multiline(v-if="isLoggedIn")
      .column.is-3-desktop.is-6-tablet(v-for='friendId in friendIds', :key='friendId')
        .box
          friend-collected(:friendId='friendId' :nickname="friends[friendId].nickname" :pokeNumber='pokeNumber' v-bind='{ showCustomTrackers }')

</template>

<script>
import { db } from '@/db';
import { isLight, collectionTypeColors } from '@/utils/color-utils.js';
import FriendCollected from './FriendCollected.vue';

const filterTypes = {
  GENERATION: 'generation',
  COLLECTED: 'collected',
  UNCOLLECTED: 'uncollected',
};

export default {
  name: 'Pokemon',
  components: { FriendCollected },
  data() {
    return {
      isLoading: true,
      pokedex: null,
      pokemonCollected: null,
      tracked: null,
      generations: null,
      username: null,
      friends: null,
      friendIds: [],
      showCustomTrackers: false,
    };
  },
  async created() {
    this.init();
  },
  watch: {
    $route() {
      this.init();
    },
  },
  computed: {
    pokeNumber() {
      return this.$route.params.pokeNumber;
    },
    prevPokeNumber() {
      const allPokeNumbers = Object.keys(this.pokedex);
      const allPokemons = Object.values(this.pokedex);
      const currentIndex = allPokeNumbers.indexOf(this.pokeNumber);
      let prevIndex = currentIndex - 1;
      while (!allPokemons[prevIndex].collectable) {
        prevIndex--;
        if (prevIndex < 0) prevIndex = allPokeNumbers.length - 1;
      }
      return allPokeNumbers[prevIndex];
    },
    nextPokeNumber() {
      const allPokeNumbers = Object.keys(this.pokedex);
      const allPokemons = Object.values(this.pokedex);
      const currentIndex = allPokeNumbers.indexOf(this.pokeNumber);
      let nextIndex = currentIndex + 1;
      while (!allPokemons[nextIndex].collectable) {
        nextIndex++;
        if (nextIndex >= allPokeNumbers.length) nextIndex = 0;
      }
      return allPokeNumbers[nextIndex];
    },
    pokemon() {
      return this.pokedex[this.pokeNumber];
    },
    isOnWishlist() {
      return this.pokemonCollected && this.pokemonCollected.wishlist;
    },
    wishlistTooltip() {
      return this.isOnWishlist ? `Remove ${this.pokemon.name} from wishlist` : `Add ${this.pokemon.name} to wishlist`;
    },
    isLoggedIn() {
      return Boolean(localStorage.getItem('userUid'));
    },
  },
  methods: {
    async init() {
      this.isLoading = true;
      this.userUid = this.$route.params.uid || localStorage.getItem('userUid');

      this.$bind('pokemonCollected', db.database.ref(`users/${this.userUid}/pokemons/${this.pokeNumber}`));

      [this.pokedex, this.generations, this.tracked, this.settings, this.username, this.friends] = await Promise.all([
        db.pokedex(),
        db.generations(),
        db.tracked(this.userUid),
        db.settings(this.userUid),
        db.username(this.userUid),
        db.friends(localStorage.getItem('userUid')),
      ]);
      this.isLoading = false;
      this.friendIds = Object.keys(this.friends);
    },
    toggleShowCustomTrackers() {
      this.showCustomTrackers = !this.showCustomTrackers;
    },
    isCollected(collectedType = 'collected') {
      return this.pokemonCollected && this.pokemonCollected[collectedType];
    },
    generationName(generationId) {
      if (this.generations[generationId]) {
        return this.generations[generationId].name;
      }
      return '';
    },
    generationNumber(generationId) {
      if (this.generations[generationId]) {
        return this.generations[generationId].number;
      }
      return '';
    },
    generationColor(generationId) {
      if (this.generations[generationId] && this.generations[generationId].color) {
        return this.generations[generationId].color;
      }
      return 'grey';
    },
    toggleCollected(collectedType = 'collected') {
      this.setCollected(this.pokeNumber, collectedType, !this.isCollected(collectedType))
        .then(() => {
          this.$toast.open({
            message: `${this.pokemon.name} updated.`,
            type: 'is-black',
            queue: false,
          });
        })
        .catch(() => {
          this.$toast.open({
            message: 'Failed to update!',
            type: 'is-danger',
            queue: false,
          });
        });
    },
    collectionButtonStyle(trackerKey, trackerColor) {
      if (this.isCollected(trackerKey)) {
        return { 'background-color': trackerColor, color: this.textColor(trackerColor) };
      }
      return '';
    },
    filterColor(filter) {
      if (filter.type === filterTypes.GENERATION) {
        return this.generationColor(filter.id);
      }
      if (filter.type === filterTypes.COLLECTED) {
        if (collectionTypeColors[filter.name]) {
          return collectionTypeColors[filter.name];
        }
        return '#222222'; //Dark
      }
      if (filter.type === filterTypes.UNCOLLECTED) {
        return '#FFFFFF'; //White
      }
    },
    filterTextColor(filter) {
      return this.textColor(this.filterColor(filter));
    },
    textColor(backgroundColor) {
      return isLight(backgroundColor) ? 'rgb(54, 54, 54)' : 'rgb(255, 255, 255)';
    },
    setCollected(pokeNumber, collectedType, status) {
      const collectedRef = db.database.ref(`users/${this.userUid}/pokemons/${pokeNumber}/${collectedType}`);
      return collectedRef.set(status);
    },
    setWishlist(status) {
      const wishlistRef = db.database.ref(`users/${this.userUid}/pokemons/${this.pokeNumber}/wishlist`);
      wishlistRef
        .set(status)
        .then(() => {
          this.$toast.open({
            message: status ? `${this.pokemon.name} added to wishlist.` : `${this.pokemon.name} removed from wishlist.`,
            type: 'is-black',
            queue: false,
          });
        })
        .catch(() => {
          this.$toast.open({
            message: 'Failed to update!',
            type: 'is-danger',
            queue: false,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  td {
    vertical-align: middle;
    padding-top: 0;
    padding-bottom: 0;
  }
  tr {
    height: 42px;
  }
}

.next-button {
  height: 120px;
  border: none;

  &:hover {
    background-color: hsl(0deg, 0%, 98%);
  }

  .next-poke-image {
    height: 100px;

    @media (max-width: 366px) {
      height: 84px;
    }
  }

  .next-icon {
    height: 70px;
    width: 30px;
  }
}

.wishlist {
  cursor: pointer;
}

.ps-checkbox {
  width: 24px;
  height: 24px;
}
</style>
