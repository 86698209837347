<template lang="pug">
  div
    .modal-card(style='width: auto')
      header.modal-card-head
        p.modal-card-title Import from CSV file
      section.modal-card-body(ref='cardBody')
        template(v-if='isReview === false')
          h2.title.is-6
            | Data needs to be in table format with text values for each type of collection.
            br
            | See following example:
            a(href='https://docs.google.com/spreadsheets/d/15NwkY4xiGmO_67LTO1UTB1OOdvon3IadP62gLx7qcQM/edit?usp=sharing' target='_blank') Pokemon Collection Example
          b-field.file
            b-upload(v-model='file')
              a.button.is-primary
                span.icon.is-small
                  font-awesome-icon(:icon="['fas', 'upload']")
                span Click to upload
            span.file-name(v-if='file')
              | {{ file.name }}
          b-field(label='Column headers', v-if="rows")
            b-select(placeholder='Select row containing all headers' :expanded='true' v-model="headerIndex")
              option(v-for='(row, index) in rows.slice(0, 10)', :value='index', :key='index')
                | {{ row.join(' | ') }}
          template(v-if='headerIndex > -1')
            b-field(label='Pokemon number column')
              b-select(placeholder='Select pokemon number column' :expanded='true' v-model="pokemonNumberIndex")
                option(:value='-1' :key='-1') -- None --
                option(v-for='(column, index) in rows[headerIndex]', :value='index', :key='index')
                  | {{ column }}
            b-field(label='Pokemon name column')
              b-select(placeholder='Select pokemon name column' :expanded='true' v-model="pokemonNameIndex")
                option(:value='-1' :key='-1') -- None --
                option(v-for='(column, index) in rows[headerIndex]', :value='index', :key='index')
                  | {{ column }}
            .columns
              .column
                b-field(label='Collected column')
                  b-select(placeholder='Select collected column' :expanded='true' v-model="collectedIndex")
                    option(:value='-1' :key='-1') -- None --
                    option(v-for='(column, index) in rows[headerIndex]', :value='index', :key='index')
                      | {{ column }}
              .column
                b-field(label='Collected value')
                  b-select(placeholder='Select value respresenting collected' :expanded='true' v-model="collectedValue")
                    option(v-for='(value, index) in uniqueValues(collectedIndex)', :value='value', :key='index')
                      | {{ value }}
            .columns
              .column
                b-field(label='Female column')
                  b-select(placeholder='Select female column' :expanded='true' v-model="femaleIndex")
                    option(:value='-1' :key='-1') -- None --
                    option(v-for='(column, index) in rows[headerIndex]', :value='index', :key='index')
                      | {{ column }}
              .column
                b-field(label='Collected value')
                  b-select(placeholder='Select value respresenting collected' :expanded='true' v-model="femaleValue")
                    option(v-for='(value, index) in uniqueValues(femaleIndex)', :value='value', :key='index')
                      | {{ value }}
            .columns
              .column
                b-field(label='Male column')
                  b-select(placeholder='Select male column' :expanded='true' v-model="maleIndex")
                    option(:value='-1' :key='-1') -- None --
                    option(v-for='(column, index) in rows[headerIndex]', :value='index', :key='index')
                      | {{ column }}
              .column
                b-field(label='Collected value')
                  b-select(placeholder='Select value respresenting collected' :expanded='true' v-model="maleValue")
                    option(v-for='(value, index) in uniqueValues(maleIndex)', :value='value', :key='index')
                      | {{ value }}
            .columns
              .column
                b-field(label='Shiny column')
                  b-select(placeholder='Select shiny column' :expanded='true' v-model="shinyIndex")
                    option(:value='-1' :key='-1') -- None --
                    option(v-for='(column, index) in rows[headerIndex]', :value='index', :key='index')
                      | {{ column }}
              .column
                b-field(label='Collected value')
                  b-select(placeholder='Select value respresenting collected' :expanded='true' v-model="shinyValue")
                    option(v-for='(value, index) in uniqueValues(shinyIndex)', :value='value', :key='index')
                      | {{ value }}
            .columns
              .column
                b-field(label='Lucky column')
                  b-select(placeholder='Select shiny column' :expanded='true' v-model="luckyIndex")
                    option(:value='-1' :key='-1') -- None --
                    option(v-for='(column, index) in rows[headerIndex]', :value='index', :key='index')
                      | {{ column }}
              .column
                b-field(label='Collected value')
                  b-select(placeholder='Select value respresenting collected' :expanded='true' v-model="luckyValue")
                    option(v-for='(value, index) in uniqueValues(luckyIndex)', :value='value', :key='index')
                      | {{ value }}
            .columns
              .column
                b-field(label='Wishlist/Interested column')
                  b-select(placeholder='Select wishlist column' :expanded='true' v-model="wishlistIndex")
                    option(:value='-1' :key='-1') -- None --
                    option(v-for='(column, index) in rows[headerIndex]', :value='index', :key='index')
                      | {{ column }}
              .column
                b-field(label='Wishlist value')
                  b-select(placeholder='Select value respresenting interested' :expanded='true' v-model="wishlistValue")
                    option(v-for='(value, index) in uniqueValues(wishlistIndex)', :value='value', :key='index')
                      | {{ value }}
            b-field(label='Wishlist comments column')
              b-select(placeholder='Select wishlist comments column' :expanded='true' v-model="wishlistCommentIndex")
                option(:value='-1' :key='-1') -- None --
                option(v-for='(column, index) in rows[headerIndex]', :value='index', :key='index')
                  | {{ column }}
        template(v-if='isReview')
          h2.title.is-6 Do you want to import the following data?
          table.table.is-striped.is-narrow.is-fullwidth
            thead
              tr
                th Pokemon
                th(v-if='collectedIndex > -1') Collected
                th(v-if='femaleIndex > -1') Female
                th(v-if='maleIndex > -1') Male
                th(v-if='shinyIndex > -1') Shiny
                th(v-if='luckyIndex > -1') Lucky
                th(v-if='wishlistIndex > -1') Wishlist
                th(v-if='wishlistCommentIndex > -1') Wishlist comment
            tbody
              tr(v-for='pokemonNumber in Object.keys(importedPokemons).sort()', v-bind:key='pokemonNumber')
                td {{ `${pokemonNumber} - ${pokedex[pokemonNumber].name}` }}
                td(v-if='collectedIndex > -1') {{ importedPokemons[pokemonNumber].collected ? 'Yes' : 'No' }}
                td(v-if='femaleIndex > -1') {{ importedPokemons[pokemonNumber].female ? 'Yes' : 'No' }}
                td(v-if='maleIndex > -1') {{ importedPokemons[pokemonNumber].male ? 'Yes' : 'No' }}
                td(v-if='shinyIndex > -1') {{ importedPokemons[pokemonNumber].shiny ? 'Yes' : 'No' }}
      footer.modal-card-foot
        button.button(v-if='!isReview' type='button', @click='$parent.close()') Close
        button.button(v-if='isReview' type='button', @click='isReview = false') Back
        button.button.is-primary(v-if='!isReview' @click="next()") Next
        button.button.is-primary(v-if='isReview' @click="importFile()") Import
</template>

<script>
import Papa from 'papaparse';
import { db } from '@/db';
import { pad } from '@/utils/common.js';

export default {
  name: 'ImportCsvFile',
  data() {
    return {
      file: null,
      isReview: false,
      rows: '',
      headerIndex: undefined,
      pokemonNumberIndex: -1,
      pokemonNameIndex: -1,
      collectedIndex: -1,
      collectedValue: undefined,
      femaleIndex: -1,
      femaleValue: undefined,
      maleIndex: -1,
      maleValue: undefined,
      shinyIndex: -1,
      shinyValue: undefined,
      luckyIndex: -1,
      luckyValue: undefined,
      wishlistIndex: -1,
      wishlistValue: undefined,
      wishlistCommentIndex: -1,
      importedPokemons: {},
      pokedex: null,
      collection: null,
    };
  },
  async created() {
    const userUid = this.$route.params.uid || localStorage.getItem('userUid');
    this.pokedex = await db.pokedex();
    this.collection = await db.userCollection(userUid);
  },
  watch: {
    file() {
      if (this.file) {
        this.clear();
        Papa.parse(this.file, {
          complete: results => {
            this.rows = results.data;
          },
        });
      }
      return '';
    },
  },
  methods: {
    clear() {
      this.headerIndex = undefined;
      this.pokemonNumberIndex = -1;
      this.pokemonNameIndex = -1;
      this.collectedIndex = -1;
      this.collectedValue = undefined;
      this.femaleIndex = -1;
      this.femaleValue = undefined;
      this.maleIndex = -1;
      this.maleValue = undefined;
      this.shinyIndex = -1;
      this.shinyValue = undefined;
      this.luckyIndex = -1;
      this.luckyValue = undefined;
      this.wishlistIndex = -1;
      this.wishlistValue = undefined;
      this.wishlistCommentIndex = -1;
      this.importedPokemons = {};
    },
    uniqueValues(column) {
      const uniqueValues = new Set();
      for (let i = this.headerIndex + 1; i < this.rows.length; i++) {
        if (this.rows[i][column]) {
          uniqueValues.add(this.rows[i][column]);
        }
      }
      return Array.from(uniqueValues.values());
    },
    next() {
      if (!this.file) {
        console.log('No file!');
        return;
      }
      const pokemonCollection = {};
      const rowsWithData = this.rows.slice(this.headerIndex + 1);
      for (const row of rowsWithData) {
        const pokemonData = {};
        if (this.collectedIndex > -1) {
          pokemonData['collected'] = row[this.collectedIndex] === this.collectedValue;
        }
        if (this.femaleIndex > -1) {
          pokemonData['female'] = row[this.femaleIndex] === this.femaleValue;
        }
        if (this.maleIndex > -1) {
          pokemonData['male'] = row[this.maleIndex] === this.maleValue;
        }
        if (this.shinyIndex > -1) {
          pokemonData['shiny'] = row[this.shinyIndex] === this.shinyValue;
        }
        if (this.luckyIndex > -1) {
          pokemonData['lucky'] = row[this.luckyIndex] === this.luckyValue;
        }
        if (this.wishlistIndex > -1) {
          pokemonData['wishlist'] = row[this.wishlistIndex] === this.wishlistValue;
        }
        if (this.wishlistCommentIndex > -1) {
          pokemonData['wishlistComment'] = row[this.wishlistCommentIndex];
        }

        if (this.pokemonNumberIndex > -1) {
          const pokemonNumber = pad(row[this.pokemonNumberIndex]);
          if (this.pokedex[pokemonNumber]) {
            pokemonCollection[pokemonNumber] = pokemonData;
          }
        } else if (this.pokemonNameIndex > -1) {
          const pokemonNumber = this.getPokemonNumber(row[this.pokemonNameIndex]);
          if (pokemonNumber) {
            pokemonCollection[pokemonNumber] = pokemonData;
          }
        }
        this.$refs.cardBody.scrollTop = 0;
      }
      this.importedPokemons = pokemonCollection;
      this.isReview = true;
    },
    getPokemonNumber(name) {
      if (name) {
        const pokedexEntry = Object.entries(this.pokedex).find(entry => {
          return entry[1] && entry[1].name && entry[1].name.toLowerCase() === name.toLowerCase();
        });
        return pokedexEntry ? pokedexEntry[1].number : undefined;
      }
    },
    importFile() {
      const userUid = this.$route.params.uid || localStorage.getItem('userUid');
      db.database.ref('users/' + userUid + '/pokemons').set(this.importedPokemons, error => console.log(error));
      this.$toast.open(`Imported ${Object.keys(this.importedPokemons).length} pokemons into collection`);
      this.$parent.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.file-name {
  max-width: 100%;
}
</style>
