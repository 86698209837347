<template lang="pug">
  div
    .modal-card(style='width: auto')
      header.modal-card-head
        p.modal-card-title Search
      section.modal-card-body
        .content
          p Filter the pokedex by searching for:
          ul
            li
              b Pokemon name
              i  use + before the name to show the whole family
            li
              b Pokemon number
            li
              b shiny
              i  finds all pokemons that can be shiny
            li
              b purified
              i  finds all pokemons that can be purified
            li
              b shadow
              i  finds all pokemons that can be shadow
            li
              b regional
              i  finds all pokemons that are regional
            li
              b legendary
              i  finds all pokemons that are legendary
            li
              b mythical
              i  finds all pokemons that are mythical
            li
              b wishlist
              i  finds all pokemons that are marked for wishlist (starred)
      footer.modal-card-foot
        button.button(type='button', @click='$parent.close()') Close
</template>

<script>
export default {
  name: 'SearchHelp',
};
</script>

<style lang="scss" scoped></style>
