<template lang="pug">
  .container
    h1.title.is-2 Admin
    .columns.is-vcentered.is-multiline
      .column.is-narrow.label-column
          label.label Generations
      .column
        .field.is-grouped.is-grouped-multiline
          .control(v-for='generation in generations', :key='generation.id')
            a(v-if='generation.id', @click='filterGeneration(generation.id)')
              GenerationTag.generation-button(:generationId="generation.id" :class="{ 'is-button-selected': generationFilter === generation.id }")
    .columns.is-vcentered.is-multiline
      .column.is-narrow.label-column
          label.label Search
      .column.is-6.is-4-widescreen
        .field.has-addons
          .control.is-expanded
            input.input(
              type="text"
              placeholder="Search pokedex for..."
              @keydown.enter="$event.target.blur()"
              v-model="filter"
            )
          .control
            a.button.is-dark(@click="filter=''") Clear
    .columns.is-mobile.is-vcentered.is-variable.is-1
      .column
        b {{ numberOfFilteredPokemons }} matching pokemons
      .column.is-narrow
        button.button.is-dark(@click="addNewPokemon()")
          span.icon.is-small
            font-awesome-icon(:icon="['fas', 'plus-circle']")
          span Add new pokemon
    .columns.is-mobile.is-vcentered.is-variable.is-1(v-if='currentFilters.length > 0')
      .column
        .field.is-grouped.is-grouped-multiline
          .control(v-for='(filter, index) in currentFilters', :key='index')
            a.tags.has-addons(@click='removeFilter(filter)')
              .tag.is-dark.filter-label(:style="{ backgroundColor: filterColor(filter), color: filterTextColor(filter) }")
                | {{ filter.name }}
              .tag.is-delete
    .container.loader.large-loader(v-if="isLoading")
    table.table.is-striped.is-narrow.is-fullwidth.is-hoverable(v-else)
      thead
        tr
          th.is-hidden-mobile.width-generation Generation
          th No
          th Pokemon
          th Collectable
      tbody
        pokedex-row(
          v-for='pokemon in filteredPokedex', :key='pokemon.number'
        )
          td.is-hidden-mobile
            a(@click.stop='generationFilter = pokemon.generation')
              GenerationTag(:generationId="pokemon.generation")
          td
            span.pokemon-number.is-block-mobile {{ pokemon.number }}
          td
            img.poke-image(:src="`https://db.pokemongohub.net/images/official/detail/${pokemon.number}.png`")
            span.icon-width.is-hidden-tablet
              b-tooltip.display-inline(:label='`Regional: ${pokemon.regional}`' type="is-dark" :active="tooltipActive" v-if="pokemon.regional")
                font-awesome-icon.icon-width(:icon="['fas', 'globe-americas']" @click.stop="tooltipActive = !tooltipActive")
            .pokemon-name
              | {{ pokemon.name }}
            span.icon-width.is-hidden-mobile
              b-tooltip.display-inline(:label='`Regional: ${pokemon.regional}`' type="is-dark" v-if="pokemon.regional")
                font-awesome-icon(:icon="['fas', 'globe-americas']")
          td
            .collection-buttons
              button.button.collection-button.is-small(
                :class="{ 'is-dark': isCollectable(pokemon.number) }",
                @click.stop="toggleCollectable(pokemon.number)"
              )
                font-awesome-icon(:icon="['fas', 'check']", v-if="isCollectable(pokemon.number)")
                font-awesome-icon(:icon="['far', 'square']", v-else)
              button.button.collection-button.is-small(
                :class="{ 'is-male': isCollectable(pokemon.number, 'male') }",
                @click.stop="toggleCollectable(pokemon.number, 'male')"
              )
                font-awesome-icon(:icon="['fas', 'mars']")
              button.button.collection-button.is-small(
                :class="{ 'is-female': isCollectable(pokemon.number, 'female') }",
                @click.stop="toggleCollectable(pokemon.number, 'female')"
              )
                font-awesome-icon(:icon="['fas', 'venus']")
              button.button.collection-button.is-small(
                :class="{ 'is-lucky': isCollectable(pokemon.number, 'lucky') }",
                @click.stop="toggleCollectable(pokemon.number, 'lucky')"
              )
                img.collection-image(src="@/assets/three-leaf-clover.png")
              button.button.collection-button.is-small(
                :class="{ 'is-shiny': isCollectable(pokemon.number, 'shiny') }",
                @click.stop="toggleCollectable(pokemon.number, 'shiny')"
              )
                img.collection-image(src="@/assets/shiny-stars.png")
              button.button.collection-button.is-small(
                :class="{ 'is-purified': isCollectable(pokemon.number, 'purified') }",
                @click.stop="toggleCollectable(pokemon.number, 'purified')"
              )
                img.collection-image(src="@/assets/purified.png")
              button.button.collection-button.is-small(
                @click.stop="editPokemon(pokemon)"
              )
                | Edit
</template>

<script>
import { db } from '@/db';
import PokedexRow from '@/components/PokedexRow';
import GenerationTag from '@/components/common/GenerationTag';
import { isLight } from '@/utils/color-utils.js';
import { sortByPokedexNumber, isPokeNumber, isGenerationId } from '@/utils/common.js';
import EditPokemon from './EditPokemon.vue';

const filterTypes = {
  GENERATION: 'generation',
  COLLECTED: 'collected',
  UNCOLLECTED: 'uncollected',
};

export default {
  name: 'Admin',
  components: {
    PokedexRow,
    GenerationTag,
  },
  data() {
    return {
      userUid: undefined,
      isLoading: true,
      tooltipActive: false,
      showConfirm: false,
      filter: '',
      generationFilter: undefined,
      collectedFilters: {},
      uncollectedFilters: {},
      selectedPokemons: {},
      filteredPokedex: [],
      pokedex: null,
      pokedexArray: [],
      generations: null,
    };
  },
  async created() {
    this.userUid = this.$route.params.uid || localStorage.getItem('userUid');

    await this.$bind('pokedex', db.database.ref('pokedex')).then(() => {
      this.pokedexArray = Object.keys(this.pokedex)
        .sort(sortByPokedexNumber)
        .map(key => this.pokedex[key]);
      this.filteredPokedex = this.pokedexArray;
    });

    this.generations = await db.generations();

    this.isLoading = false;
  },
  watch: {
    filter() {
      this.applyFilters();
    },
    generationFilter() {
      this.applyFilters();
    },
    collectedFilters() {
      this.applyFilters();
    },
    uncollectedFilters() {
      this.applyFilters();
    },
  },
  computed: {
    currentFilters() {
      const filters = [];
      if (this.generationFilter) {
        const filterObject = {
          id: this.generationFilter,
          name: this.generations[this.generationFilter].name,
          type: filterTypes.GENERATION,
        };
        filters.push(filterObject);
      }
      for (let filter of Object.keys(this.collectedFilters)) {
        filters.push({
          id: filter,
          name: this.filterName(filter),
          type: filterTypes.COLLECTED,
        });
      }
      for (let filter of Object.keys(this.uncollectedFilters)) {
        const filterName = filter === 'collected' ? 'uncollected' : this.filterName(filter);
        filters.push({
          id: filter,
          name: filterName,
          type: filterTypes.UNCOLLECTED,
        });
      }
      return filters;
    },
    numberOfFilteredPokemons() {
      return Object.keys(this.filteredPokedex).length;
    },
    isLoggedIn() {
      return Boolean(localStorage.getItem('userUid'));
    },
  },
  methods: {
    applyFilters() {
      const filter = this.filter.trim();
      if (isPokeNumber(filter)) {
        this.filteredPokedex = this.pokedexArray.filter(pokemon => Number(pokemon.number) === Number(filter));
      } else if (isGenerationId(filter.toLowerCase())) {
        this.filteredPokedex = this.pokedexArray.filter(pokemon => pokemon.generation === filter.toLowerCase());
      } else if (filter.toLowerCase() === 'regional') {
        this.filteredPokedex = this.pokedexArray.filter(pokemon => pokemon.regional);
      } else if (filter.toLowerCase() === 'shiny') {
        this.filteredPokedex = this.pokedexArray.filter(pokemon => pokemon.shiny);
      } else if (filter.toLowerCase() === 'purified') {
        this.filteredPokedex = this.pokedexArray.filter(pokemon => pokemon.purified);
      } else if (filter.toLowerCase() === 'shadow') {
        this.filteredPokedex = this.pokedexArray.filter(pokemon => pokemon.purified);
      } else if (filter.toLowerCase() === 'legendary') {
        this.filteredPokedex = this.pokedexArray.filter(
          pokemon => pokemon.legendary && pokemon.legendary.toLowerCase() === 'legendary'
        );
      } else if (filter.toLowerCase() === 'mythical') {
        this.filteredPokedex = this.pokedexArray.filter(
          pokemon => pokemon.legendary && pokemon.legendary.toLowerCase() === 'mythical'
        );
      } else if (filter.startsWith('+')) {
        const trimmedFilter = filter.substring(1).trim().toLowerCase();
        const filteredByName = this.pokedexArray.filter(pokemon =>
          pokemon.name.toLowerCase().startsWith(trimmedFilter)
        );
        const familyNames = filteredByName.map(pokemon => pokemon.family);
        this.filteredPokedex = this.pokedexArray.filter(pokemon =>
          familyNames.find(familyName => familyName.toLowerCase() === pokemon.family.toLowerCase())
        );
      } else {
        this.filteredPokedex = this.pokedexArray.filter(pokemon =>
          pokemon.name.toLowerCase().startsWith(filter.toLowerCase())
        );
      }

      if (this.generationFilter) {
        this.filteredPokedex = this.filteredPokedex.filter(pokemon => pokemon.generation === this.generationFilter);
      }

      this.tempPokedex = this.filteredPokedex.slice(0, 30);
      this.timer = Date.now();
    },
    removeFilter(filter) {
      if (filter.type === filterTypes.GENERATION) {
        this.generationFilter = undefined;
      }
      if (filter.type === filterTypes.COLLECTED) {
        this.$delete(this.collectedFilters, filter.id);
      }
      if (filter.type === filterTypes.UNCOLLECTED) {
        this.$delete(this.uncollectedFilters, filter.id);
      }
    },
    filterGeneration(generationId) {
      if (this.generationFilter === generationId) {
        this.generationFilter = undefined;
      } else {
        this.generationFilter = generationId;
      }
    },
    selectPokemon(pokeNumber, selected) {
      if (selected) {
        this.selectedPokemons[pokeNumber] = selected;
      } else {
        delete this.selectedPokemons[pokeNumber];
      }
    },
    toggleCollectable(pokeNumber, collectedType = 'collectable') {
      if (this.selectedPokemons[pokeNumber] && Object.keys(this.selectedPokemons).length > 1) {
        this.setAllSelectedCollectable(collectedType, !this.isCollectable(pokeNumber, collectedType));
      } else {
        this.setCollectable(pokeNumber, collectedType, !this.isCollectable(pokeNumber, collectedType))
          .then(() => {
            this.$toast.open({
              message: `${this.pokedex[pokeNumber].name} updated.`,
              type: 'is-black',
              queue: false,
            });
          })
          .catch(() => {
            this.$toast.open({
              message: 'Failed to update!',
              type: 'is-danger',
              queue: false,
            });
          });
      }
    },
    setCollectable(pokeNumber, collectedType, status) {
      const collectedRef = db.database.ref(`pokedex/${pokeNumber}/${collectedType}`);
      return collectedRef.set(status);
    },
    isCollectable(pokeNumber, collectedType = 'collectable') {
      return this.pokedex[pokeNumber] && this.pokedex[pokeNumber][collectedType];
    },
    setAllSelectedCollectable(collectedType = 'collected', collected) {
      this.$dialog.confirm({
        title: 'Update all selected',
        message: `Are you sure you want update <b>${Object.keys(this.selectedPokemons).length}</b> selected rows?`,
        type: 'is-dark',
        confirmText: 'Yes',
        //scroll: 'keep', Makes scroll flip out if scrolled far down on page
        onConfirm: () => {
          for (const pokeNumber in this.selectedPokemons) {
            const pokemon = this.pokedexArray.find(pokemon => pokemon.number === pokeNumber);
            if (pokemon && this.collectable(pokemon, collectedType)) {
              this.setCollectable(pokeNumber, collectedType, collected);
            }
          }
          this.$toast.open(`Updated ${Object.keys(this.selectedPokemons).length} rows`);
        },
      });
    },
    generationColor(generationId) {
      if (this.generations[generationId] && this.generations[generationId].color) {
        return this.generations[generationId].color;
      }
      return 'grey';
    },
    filterColor(filter) {
      if (filter.type === filterTypes.GENERATION) {
        return this.generationColor(filter.id);
      }
    },
    filterTextColor(filter) {
      return this.textColor(this.filterColor(filter));
    },
    filterName(filterId) {
      return filterId;
    },
    textColor(backgroundColor) {
      return isLight(backgroundColor) ? 'rgb(54, 54, 54)' : 'rgb(255, 255, 255)';
    },
    addNewPokemon() {
      this.$modal.open({
        parent: this,
        component: EditPokemon,
      });
    },
    editPokemon(pokemon) {
      this.$modal.open({
        parent: this,
        component: EditPokemon,
        props: {
          number: pokemon.number,
          evolutionNumber: pokemon.evolutionNumber,
          name: pokemon.name,
          family: pokemon.family,
          generation: pokemon.generation,
          type1: pokemon.type1,
          type2: pokemon.type2,
          legendary: pokemon.legendary,
          collectable: pokemon.collectable,
          female: pokemon.female,
          male: pokemon.male,
          lucky: pokemon.lucky,
          shiny: pokemon.shiny,
          purified: pokemon.purified,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.label-column {
  width: 130px;
  padding-right: 0;
  @media only screen and (max-width: 768px) {
    padding-bottom: 0;
  }
}

.collection-filters {
  padding-top: 0;
  padding-bottom: 0;

  button {
    font-weight: 600;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-right: 0.5em;
  }
}

.filter-label {
  border: 1px solid #e4e4e4;
}

.width-generation {
  width: 170px;
}

.table td {
  vertical-align: middle;
  padding-top: 0;
  padding-bottom: 0;
}

.poke-image {
  vertical-align: middle;
  height: 42px;
  padding-right: 4px;
  transition: height 0.15s;

  &:hover {
    height: 128px;
  }
  @media (max-width: 768px) {
    transition: height 0.3s;
  }
  @media (max-width: 366px) {
    height: 84px;
  }
}

.pokemon-name {
  width: 100px;
  font-weight: 600;
  display: inline-block;
}

.pokemon-number {
  width: 28px;
  margin: auto;
}

@media (min-width: 367px) {
  .icon-width {
    display: inline-block;
    height: 20px;
    width: 20px;
  }
}
</style>
