<template lang="pug">
  div
    .modal-card(style='width: auto')
      header.modal-card-head
        p.modal-card-title Share Collection
      section.modal-card-body
        .field
          | Copy a link below to share your collection with others.
        .field
          .label All pokemons
          .control.has-icons-right
            input.input(:value='shareLink' readonly ref='shareInput' @click.stop='copyLink("shareInput")')
            a.icon.is-medium.is-right
              font-awesome-icon(:icon="['far', 'copy']")
        .field
          .label With current filters
          .control.has-icons-right
            input.input(:value='shareLinkWithFilters' readonly ref='shareWithFilterInput' @click.stop='copyLink("shareWithFilterInput")')
            a.icon.is-medium.is-right
              font-awesome-icon(:icon="['far', 'copy']")
      footer.modal-card-foot
        button.button.is-primary(type='button', @click='$parent.close()') Close
</template>

<script>
export default {
  name: 'ShareCollection',
  data() {
    return {
      userUid: localStorage.getItem('userUid'),
    };
  },
  computed: {
    shareLink() {
      return `${location.origin}/collection/${this.userUid}`;
    },
    shareLinkWithFilters() {
      return `${location.origin}/collection/${this.userUid}${window.location.search}`;
    },
  },
  methods: {
    copyLink(inputRef) {
      this.$refs[inputRef].select();
      document.execCommand('copy');
      this.$toast.open({
        message: 'Copied!',
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
