<template lang="pug">
   .container
    h1.title.is-2 Overview
    .container.loader.large-loader(v-if="isLoading")
    .template(v-else)
      .columns.is-multiline.has-text-centered
        .column
          .card
            header.card-header.total-header
              .card-header-title.is-centered.generation-header
                | Total
            .card-rows.has-text-centered
              .card-row(v-if="tracked.collected")
                .collection-header.has-background-white-ter
                  span.icon.is-collected
                    font-awesome-icon(:icon="['fas', 'check']")
                  .collection-title Collected
                router-link.center-box(:to="{ name: 'collection', params: $route.params, query: { uncollected: 'collected' } }")
                  | {{ `${collectedCount('collected')} / ${totalCount()}` }}
              .card-row(v-if="tracked.shiny")
                .collection-header.has-background-white-ter
                  span.icon.is-shiny
                    img(src="@/assets/shiny-stars.png")
                  .collection-title Shiny
                router-link.center-box(:to="{ name: 'collection', params: $route.params, query: { uncollected: 'shiny' } }")
                  | {{ `${collectedCount('shiny')} / ${totalCount('shiny')}` }}
              .card-row(v-if="tracked.male")
                .collection-header.has-background-white-ter
                  span.icon.is-male
                    font-awesome-icon(:icon="['fas', 'mars']")
                  .collection-title Male
                router-link.center-box(:to="{ name: 'collection', params: $route.params, query: { uncollected: 'male' } }")
                  | {{ `${collectedCount('male')} / ${totalCount('male')}` }}
              .card-row(v-if="tracked.female")
                .collection-header.has-background-white-ter
                  span.icon.is-female
                    font-awesome-icon(:icon="['fas', 'venus']")
                  .collection-title Female
                router-link.center-box(:to="{ name: 'collection', params: $route.params, query: { uncollected: 'female' } }")
                  | {{ `${collectedCount('female')} / ${totalCount('female')}` }}
              .card-row(v-if="tracked.lucky")
                .collection-header.has-background-white-ter
                  span.icon.is-lucky
                    img(src="@/assets/three-leaf-clover.png")
                  .collection-title Lucky
                router-link.center-box(:to="{ name: 'collection', params: $route.params, query: { uncollected: 'lucky' } }")
                  | {{ `${collectedCount('lucky')} / ${totalCount('lucky')}` }}
              .card-row(v-if="tracked.purified")
                .collection-header.has-background-white-ter
                  span.icon.is-purified
                    img(src="@/assets/purified.png")
                  .collection-title Purified
                router-link.center-box(:to="{ name: 'collection', params: $route.params, query: { uncollected: 'purified' } }")
                  | {{ `${collectedCount('purified')} / ${totalCount('purified')}` }}
        .column(v-for="generation in generations" :key="generation.id")
          .card(v-if='generation.id')
            header.card-header(:style="{ backgroundColor: generationColor(generation.id) }")
              .card-header-title.is-centered.generation-header
                | {{ `Generation ${generation.number} - ${generation.name}` }}
            .card-rows.has-text-centered
              .card-row(v-if="tracked.collected")
                .collection-header.has-background-white-ter
                  span.icon.is-collected
                    font-awesome-icon(:icon="['fas', 'check']")
                  .collection-title Collected
                router-link.center-box(:to="{ name: 'collection', params: $route.params, query: { generation: generation.id, uncollected: 'collected' } }")
                  | {{ `${collectedCount('collected', generation.id)} / ${totalGenerationCount(generation.id)}` }}
              .card-row(v-if="tracked.shiny")
                .collection-header.has-background-white-ter
                  span.icon.is-shiny
                    img(src="@/assets/shiny-stars.png")
                  .collection-title Shiny
                router-link.center-box(:to="{ name: 'collection', params: $route.params, query: { generation: generation.id, uncollected: 'shiny' } }")
                  | {{ `${collectedCount('shiny', generation.id)} / ${totalGenerationCount(generation.id, 'shiny')}` }}
              .card-row(v-if="tracked.male")
                .collection-header.has-background-white-ter
                  span.icon.is-male
                    font-awesome-icon(:icon="['fas', 'mars']")
                  .collection-title Male
                router-link.center-box(:to="{ name: 'collection', params: $route.params, query: { generation: generation.id, uncollected: 'male' } }")
                  | {{ `${collectedCount('male', generation.id)} / ${totalGenerationCount(generation.id, 'male')}` }}
              .card-row(v-if="tracked.female")
                .collection-header.has-background-white-ter
                  span.icon.is-female
                    font-awesome-icon(:icon="['fas', 'venus']")
                  .collection-title Female
                router-link.center-box(:to="{ name: 'collection', params: $route.params, query: { generation: generation.id, uncollected: 'female' } }")
                  | {{ `${collectedCount('female', generation.id)} / ${totalGenerationCount(generation.id, 'female')}` }}
              .card-row(v-if="tracked.lucky")
                .collection-header.has-background-white-ter
                  span.icon.is-lucky
                    img(src="@/assets/three-leaf-clover.png")
                  .collection-title Lucky
                router-link.center-box(:to="{ name: 'collection', params: $route.params, query: { generation: generation.id, uncollected: 'lucky' } }")
                  | {{ `${collectedCount('lucky', generation.id)} / ${totalGenerationCount(generation.id, 'lucky')}` }}
              .card-row(v-if="tracked.purified")
                .collection-header.has-background-white-ter
                  span.icon.is-purified
                    img(src="@/assets/purified.png")
                  .collection-title Purified
                router-link.center-box(:to="{ name: 'collection', params: $route.params, query: { generation: generation.id, uncollected: 'purified' } }")
                  | {{ `${collectedCount('purified', generation.id)} / ${totalGenerationCount(generation.id, 'purified')}` }}
        .column
</template>

<script>
import { db } from '@/db';

export default {
  name: 'Overview',
  data() {
    return {
      isLoading: true,
      isLoadingPokedex: true,
      pokemonNumbers: {},
      pokedex: null,
      collection: null,
      tracked: null,
      generations: null,
      settings: null,
    };
  },
  async created() {
    const userUid = this.$route.params.uid || localStorage.getItem('userUid');

    [this.pokedex, this.generations, this.collection, this.tracked, this.settings] = await Promise.all([
      db.pokedex(),
      db.generations(),
      db.userCollection(userUid),
      db.tracked(userUid),
      db.settings(userUid),
    ]);
    this.isLoading = false;
  },
  methods: {
    generationColor(generationId) {
      if (this.generations[generationId] && this.generations[generationId].color) {
        return this.generations[generationId].color;
      }
      return 'grey';
    },
    collectedCount(collectedType, generationId) {
      return Object.entries(this.collection).filter(([pokeNumber, pokemon]) => {
        return (
          (!generationId || this.generationPokemonNumbers(generationId).includes(pokeNumber)) && pokemon[collectedType]
        );
      }).length;
    },
    generationPokemonNumbers(generationId) {
      if (!this.pokemonNumbers[generationId]) {
        this.pokemonNumbers[generationId] = Object.values(this.pokedex)
          .filter(pokemon => pokemon.generation === generationId)
          .map(pokemon => pokemon.number + '');
      }
      return this.pokemonNumbers[generationId];
    },
    totalGenerationCount(generationId, collectedType = 'collectable') {
      return Object.values(this.pokedex).filter(
        pokemon => pokemon.generation === generationId && pokemon.collectable && pokemon[collectedType]
      ).length;
    },
    totalCount(collectedType = 'collectable') {
      return Object.values(this.pokedex).filter(pokemon => pokemon.collectable && pokemon[collectedType]).length;
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  color: rgb(74, 74, 74);

  &:hover {
    text-decoration: underline;
    color: black;
  }
}
.generation-header {
  color: white;
  font-weight: normal;
}
.card {
  min-width: 260px;
  border-radius: 5px;

  .card-rows {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px 10px;
    padding: 10px;
    padding-top: 16px;
    padding-bottom: 16px;

    .card-row {
      border-left: 1px solid whitesmoke;
      border-right: 1px solid whitesmoke;
      border-bottom: 1px solid whitesmoke;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
}

.center-box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em;
}

.collection-header {
  display: flex;
  align-items: center;

  .icon {
    height: 36px;
    width: 36px;
    padding: 5px;
    border-radius: 5px;
  }

  .collection-title {
    padding-left: 10px;
    font-weight: bold;
    //width: calc(100% - 36px);
  }
}

.total-header {
  background-color: rgb(63, 63, 63);
}
</style>
