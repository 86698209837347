import { db } from '@/db';
import Vue from 'vue';
import Buefy from 'buefy';
import 'buefy/dist/buefy.css';
import VueRouter from 'vue-router';
import App from './App.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { faCheckSquare } from '@fortawesome/free-regular-svg-icons';
import { faMars } from '@fortawesome/free-solid-svg-icons';
import { faVenus } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
import { faStar as fasStar } from '@fortawesome/free-solid-svg-icons';
import { faGlobeAmericas } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faClipboardList } from '@fortawesome/free-solid-svg-icons';
import { faListAlt } from '@fortawesome/free-regular-svg-icons';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Login from '@/components/Login';
import Collection from '@/components/collection/Collection';
import Settings from '@/components/Settings';
import Wishlist from '@/components/Wishlist';
import Friends from '@/components/Friends';
import Admin from '@/components/admin/Admin';
import AdminLog from '@/components/admin/AdminLog';
import Overview from '@/components/Overview';
import Pokemon from '@/components/Pokemon';
import PvpCounters from '@/components/pvp-counters/PvpCounters';
import { rtdbPlugin as VueFire } from 'vuefire';

library.add(faSquare);
library.add(faCheckSquare);
library.add(faMars);
library.add(faVenus);
library.add(faCheck);
library.add(faBars);
library.add(faSignOutAlt);
library.add(faUpload);
library.add(faCog);
library.add(faCopy);
library.add(farStar);
library.add(fasStar);
library.add(faGlobeAmericas);
library.add(faUser);
library.add(faQuestionCircle);
library.add(faTimesCircle);
library.add(faTimes);
library.add(faArrowLeft);
library.add(faChevronLeft);
library.add(faChevronRight);
library.add(faClipboardList);
library.add(faListAlt);
library.add(faPlusCircle);

db.init();

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.use(VueFire, { bindName: '$bind', unbindName: '$unbind' });
Vue.use(Buefy, {
  defaultIconPack: 'fas',
  defaultIconComponent: 'font-awesome-icon',
});

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: 'Login',
    },
  },
  {
    path: '/',
    name: 'collection',
    component: Collection,
  },
  {
    path: '/collection/:uid',
    component: Collection,
    meta: {
      title: 'Collection',
    },
  },
  {
    path: '/settings/',
    name: 'settings',
    component: Settings,
    meta: {
      title: 'Settings',
    },
  },
  {
    path: '/wishlist/',
    name: 'wishlist',
    component: Wishlist,
    meta: {
      title: 'Wishlist',
    },
  },
  {
    path: '/wishlist/:uid',
    component: Wishlist,
    meta: {
      title: 'Wishlist',
    },
  },
  {
    path: '/friends/',
    name: 'friends',
    component: Friends,
    meta: {
      title: 'Friends',
    },
  },
  {
    path: '/admin/',
    name: 'admin',
    component: Admin,
    meta: {
      title: 'Admin',
    },
  },
  {
    path: '/adminlog/',
    name: 'adminlog',
    component: AdminLog,
    meta: {
      title: 'Admin log',
    },
  },
  {
    path: '/overview/',
    name: 'overview',
    component: Overview,
    meta: {
      title: 'Overview',
    },
  },
  {
    path: '/overview/:uid',
    component: Overview,
    meta: {
      title: 'Overview',
    },
  },
  {
    path: '/pvpcounters/',
    name: 'pvpcounters',
    component: PvpCounters,
    meta: {
      title: 'PvP Counters',
    },
  },
  {
    path: '/pokemon/:pokeNumber',
    name: 'pokemon',
    component: Pokemon,
    meta: {
      title: 'Pokemon',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

new Vue({
  render: h => h(App),
  router,
}).$mount('#app');
