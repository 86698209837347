<template lang="pug">
   .container
    h1.title.is-2 Friends
      button.button.is-link.is-medium.is-pulled-right(@click="addFriend()")
        | + Add
    h2.title.is-5(v-if="!isLoggedIn") 
      router-link(to='/login') Login 
      | to view your friends
    template(v-else)
      .container.loader.large-loader(v-if="isLoading")
      .template(v-else)
        .columns.is-multiline
          .column.is-3-fullhd.is-4-desktop.is-6-tablet(v-for="friend in orderedFriendsList", :key="friend.id")
            router-link(:to="`/collection/${friend.id}`")
              .card
                .card-content
                  .media
                    .media-left
                      figure
                        font-awesome-icon.image.is-48x48(:icon="['fas', 'user']")
                    .media-content
                      p.title.is-5.friend-name
                        | {{ friend.username || 'Anonymous' }}
                      p.subtitle.is-6
                        | {{ friend.nickname }}
</template>

<script>
import { db } from '@/db';
import _ from 'lodash';
import AddFriend from '@/components/AddFriend';

export default {
  name: 'Friends',
  components: {
    AddFriend,
  },
  data() {
    return {
      isLoading: true,
      friendsList: {},
    };
  },
  created() {
    const userUid = localStorage.getItem('userUid');
    const friendsRef = db.database.ref('users/' + userUid + '/friends');
    friendsRef.orderByChild('nickname').on('child_added', snap => {
      const usernameRef = db.database.ref(`users/${snap.key}/settings/username`);
      usernameRef.once('value').then(usernameSnap => {
        this.isLoading = false;
        this.$set(this.friendsList, snap.key, {
          nickname: snap.val().nickname,
          username: usernameSnap.val(),
          id: snap.key,
        });
      });
    });
  },
  computed: {
    isLoggedIn() {
      return Boolean(localStorage.getItem('userUid'));
    },
    orderedFriendsList() {
      return _.orderBy(this.friendsList, [friend => friend.username && friend.username.toLowerCase()]);
    },
  },
  methods: {
    addFriend() {
      this.$modal.open({
        parent: this,
        component: AddFriend,
        width: 600,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.friend-name {
  word-break: keep-all;
}
</style>
