<template lang="pug">
div
  h5.title.is-5 {{ `${nickname} ` }}
    small(v-if='username') {{ `(${username})` }}
  table.table.is-fullwidth
    tr
      td
        label.label Collected:
      td
        .collection-buttons
          button.button.collection-button.is-small(
            :class="{ 'is-dark': isCollected() }",
          )
            font-awesome-icon(:icon="['fas', 'check']", v-if="isCollected()")
            font-awesome-icon(:icon="['far', 'square']", v-else)
    tr(v-if="pokemon?.male")
      td
        label.label Male:
      td
        .collection-buttons
          button.button.collection-button.is-small(
            :class="{ 'is-male': isCollected('male') }",
            @click.stop="toggleCollected('male')"
          )
            font-awesome-icon(:icon="['fas', 'check']", v-if="isCollected('male')")
            font-awesome-icon(:icon="['far', 'square']", v-else)
    tr(v-if="pokemon?.female")
      td
        label.label Female:
      td
        .collection-buttons
          button.button.collection-button.is-small(
            :class="{ 'is-female': isCollected('female') }",
            @click.stop="toggleCollected('female')"
          )
            font-awesome-icon(:icon="['fas', 'check']", v-if="isCollected('female')")
            font-awesome-icon(:icon="['far', 'square']", v-else)
    tr(v-if="pokemon?.lucky")
      td
        label.label Lucky:
      td
        .collection-buttons
          button.button.collection-button.is-small(
            :class="{ 'is-lucky': isCollected('lucky') }",
            @click.stop="toggleCollected('lucky')"
          )
            font-awesome-icon(:icon="['fas', 'check']", v-if="isCollected('lucky')")
            font-awesome-icon(:icon="['far', 'square']", v-else)
    tr(v-if="pokemon?.shiny")
      td
        label.label Shiny:
      td
        .collection-buttons
          button.button.collection-button.is-small(
            :class="{ 'is-shiny': isCollected('shiny') }",
            @click.stop="toggleCollected('shiny')"
          )
            font-awesome-icon(:icon="['fas', 'check']", v-if="isCollected('shiny')")
            font-awesome-icon(:icon="['far', 'square']", v-else)
    tr(v-if="pokemon?.purified")
      td
        label.label Purified:
      td
        .collection-buttons
          button.button.collection-button.is-small(
            :class="{ 'is-purified': isCollected('purified') }",
            @click.stop="toggleCollected('purified')"
          )
            font-awesome-icon(:icon="['fas', 'check']", v-if="isCollected('purified')")
            font-awesome-icon(:icon="['far', 'square']", v-else)
    template(v-if="showCustomTrackers")
      tr(v-for='(tracker, key) in tracked?.custom', :key='key')
        template(v-if="tracker.active")
          td
            label.label {{ tracker.name }}:
          td
            .collection-buttons
              button.button.collection-button.is-small(
                :style="collectionButtonStyle(key, tracker.color)"
                @click.stop="toggleCollected(key)"
              )
                font-awesome-icon(:icon="['fas', 'check']", v-if="isCollected(key)")
                font-awesome-icon(:icon="['far', 'square']", v-else)
</template>

<script>
import { db } from '@/db';
import { isLight } from '@/utils/color-utils.js';

export default {
  name: 'FriendCollected',
  props: {
    friendId: String,
    nickname: String,
    pokeNumber: String,
    showCustomTrackers: Boolean,
  },
  data() {
    return {
      pokemonCollected: null,
      username: null,
      tracked: null,
      pokemon: null,
    };
  },
  async created() {
    [this.pokemonCollected, this.username, this.tracked, this.pokemon] = await Promise.all([
      db.pokemonCollected(this.friendId, this.pokeNumber),
      db.username(this.friendId),
      db.tracked(this.friendId),
      db.pokemon(this.pokeNumber),
    ]);
  },
  computed: {
    name() {
      return this.nickname || this.username;
    },
  },
  methods: {
    isCollected(collectedType = 'collected') {
      return this.pokemonCollected && this.pokemonCollected[collectedType];
    },
    collectionButtonStyle(trackerKey, trackerColor) {
      if (this.isCollected(trackerKey)) {
        return { 'background-color': trackerColor, color: this.textColor(trackerColor) };
      }
      return '';
    },
    textColor(backgroundColor) {
      return isLight(backgroundColor) ? 'rgb(54, 54, 54)' : 'rgb(255, 255, 255)';
    },
  },
};
</script>

<style lang="scss" scoped>
.table td {
  vertical-align: middle;
  padding-top: 0;
  padding-bottom: 0;
}
</style>
