<template lang="pug">
  div.color-picker(ref='colorpicker')
    button.button(@click='togglePicker()')
      .current-color(:style="'background-color: ' + colorValue")
    chrome-picker(:value='colors', @input='updateFromPicker', v-if='displayPicker')

</template>

<script>
import { Chrome } from 'vue-color';

export default {
  name: 'ColorPicker',
  components: {
    'chrome-picker': Chrome,
  },
  props: {
    value: String,
  },
  data() {
    return {
      colors: {
        hex: '#1E8B6A',
      },
      colorValue: '',
      displayPicker: false,
    };
  },
  mounted() {
    this.setColor(this.value || '#1E8B6A');
  },
  methods: {
    setColor(color) {
      this.updateColors(color);
      this.colorValue = color;
    },
    updateColors(color) {
      if (color.slice(0, 1) == '#') {
        this.colors = {
          hex: color,
        };
      } else if (color.slice(0, 4) == 'rgba') {
        var rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(','),
          hex =
            '#' +
            ((1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) + parseInt(rgba[2]))
              .toString(16)
              .slice(1);
        this.colors = {
          hex: hex,
          a: rgba[3],
        };
      }
    },
    showPicker() {
      document.addEventListener('click', this.documentClick);
      this.displayPicker = true;
    },
    hidePicker() {
      document.removeEventListener('click', this.documentClick);
      this.displayPicker = false;
    },
    togglePicker() {
      this.displayPicker ? this.hidePicker() : this.showPicker();
    },
    updateFromInput() {
      this.updateColors(this.colorValue);
    },
    updateFromPicker(color) {
      this.colors = color;
      if (color.rgba.a == 1) {
        this.colorValue = color.hex;
      } else {
        this.colorValue =
          'rgba(' + color.rgba.r + ', ' + color.rgba.g + ', ' + color.rgba.b + ', ' + color.rgba.a + ')';
      }
    },
    documentClick(event) {
      const colorPickerElement = this.$refs.colorpicker;
      const target = event.target;
      if (colorPickerElement && colorPickerElement !== target && !colorPickerElement.contains(target)) {
        this.hidePicker();
      }
    },
  },
  watch: {
    colorValue(val) {
      if (val) {
        this.updateColors(val);
        this.$emit('input', val);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.color-picker {
  display: inline-block;
}

.vc-chrome {
  position: absolute;
  z-index: 9;
}

.current-color {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: #000;
  cursor: pointer;
}
</style>
