<template lang="pug">
  .container
    h1.title.is-2 PvP Counters
    h2.title.is-6
      | Create your team at #[a(href="https://pvpoke.com/team-builder") https://pvpoke.com/team-builder]. Click 'Rate Team' and then 'Export All Matchups to CSV'.
    b-field.file
      b-upload(v-model='file')
        a.button.is-primary
          span.icon.is-small
            font-awesome-icon(:icon="['fas', 'upload']")
          span Click to import
      span.file-name(v-if='file')
        | {{ file.name }}
    hr
    template(v-if="counters.length > 0")
      .columns.is-vcentered.is-multiline
        .column.is-6.is-4-widescreen
          .field.has-addons
            .control.is-expanded
              input.input(
                type="text"
                placeholder="Filter counters..."
                @keydown.enter="$event.target.blur()"
                v-model="filter"
              )
            .control
              a.button.is-dark(@click="filter=''") Clear
      table.counter-table.table.is-striped.is-narrow.is-fullwidth
        thead
          tr
            th(@click="sortByRank()") Rank
            th(@click="sortByName()") Opponent
            th.align-center(v-for="(teamMember, index) in teamMembers" @click="sortByMember(index)")
              span.pokemon-wrapper
                b-tooltip.display-inline.action-button(:label='teamMember' type="is-dark")
                  img.poke-image(:src="`https://db.pokemongohub.net/images/official/detail/${pokemonNumber(teamMember)}.png`")
                  img.shadow-icon(src="@/assets/shadow.png" v-if="isShadow(teamMember)")
            th.align-center(@click="sortByThreatScore()") Threat Score
        tbody
          tr(v-for="(counter, index) in filteredCounters")
            td {{ counter.rank }}
            td.pokemon-cell
              span.pokemon-wrapper
                b-tooltip.display-inline.action-button(:label='counter.name' type="is-dark")
                  img.poke-image(:src="`https://db.pokemongohub.net/images/official/detail/${pokemonNumber(counter.name)}.png`")
                  img.shadow-icon(src="@/assets/shadow.png" v-if="isShadow(counter.name)")
              span.pokemon-name.is-hidden-mobile {{ counter.name }}
            template(v-for="score in counter.counterScores")
              td.align-center(:style="{ background: backgroundColor(score) }") {{ score }}
            td.align-center(:style="{ background: backgroundColor(counter.threatScore) }") {{ counter.threatScore }}

</template>

<script>
import { db } from '@/db';
import Papa from 'papaparse';
import chroma from 'chroma-js';

//const RED = '#db1304';
const RED = '#d11730';
const GREEN = '#007d3e';

export default {
  name: 'PvpCounters',
  components: {},
  data() {
    return {
      file: null,
      teamSize: 0,
      teamMembers: [],
      counters: [],
      sorting: 0,
      sortDesc: true,
      pokedex: null,
      filter: '',
    };
  },
  async created() {
    this.pokedex = await db.pokedex();
  },
  watch: {
    file() {
      if (this.file) {
        Papa.parse(this.file, {
          complete: results => {
            this.teamSize = results.data[0].length - 3;
            this.teamMembers = results.data[0].slice(1, this.teamSize + 1);
            this.counters = results.data.slice(1).map(counter => {
              return {
                name: counter[0],
                counterScores: counter.slice(1, this.teamSize + 1),
                threatScore: counter[this.teamSize + 1],
                overallRating: counter[this.teamSize + 2],
              };
            });
            this.sortDesc = true;
            this.sorting = 0;
            this.sortByRank();
            this.counters = this.counters.map((counter, index) => {
              return { ...counter, rank: index + 1 };
            });
          },
        });
      }
      return '';
    },
  },
  computed: {
    filteredCounters() {
      const filterList = this.filter.split('+');
      return this.counters.filter(counter => {
        for (let filter of filterList) {
          if (counter.name && counter.name.toLowerCase().startsWith(filter.toLowerCase())) {
            return true;
          }
        }
        return false;
      });
    },
  },
  methods: {
    pokemonNumber(name) {
      const pokemon = Object.values(this.pokedex).find(pokemon => name && name.startsWith(pokemon.name));
      return pokemon ? pokemon.number : 0;
    },
    isShadow(name) {
      return name && name.indexOf('(Shadow)') !== -1;
    },
    scaledColor(value) {
      if (value >= 0.5) {
        const scale = chroma.scale(['white', RED]);
        return scale((value - 0.5) / 0.5).hex();
      } else {
        const scale = chroma.scale([GREEN, 'white']);
        return scale(value / 0.5).hex();
      }
    },
    backgroundColor(value) {
      return this.scaledColor(value / 1000);
    },
    sortByRank() {
      if (this.sorting === 'number') {
        this.sortDesc = !this.sortDesc;
      }
      this.sorting = 'number';
      this.counters.sort((entryA, entryB) =>
        this.sortDesc ? entryB.overallRating - entryA.overallRating : entryA.overallRating - entryB.overallRating
      );
    },
    sortByMember(index) {
      if (this.sorting === 'member' + index) {
        this.sortDesc = !this.sortDesc;
      }
      this.sorting = 'member' + index;
      this.counters.sort((entryA, entryB) =>
        this.sortDesc
          ? entryB.counterScores[index] - entryA.counterScores[index]
          : entryA.counterScores[index] - entryB.counterScores[index]
      );
    },
    sortByThreatScore() {
      if (this.sorting === 'threatScore') {
        this.sortDesc = !this.sortDesc;
      }
      this.sorting = 'threatScore';
      this.counters.sort((entryA, entryB) =>
        this.sortDesc ? entryB.threatScore - entryA.threatScore : entryA.threatScore - entryB.threatScore
      );
    },
    sortByName() {
      if (this.sorting === 'name') {
        this.sortDesc = !this.sortDesc;
      }
      this.sorting = 'name';
      //this.counters.sort((entryA, entryB) => (this.sortDesc ? entryB.name - entryA.name : entryA.name - entryB.name));
      this.counters.sort((entryA, entryB) => {
        if (entryA.name > entryB.name) return this.sortDesc ? 1 : -1;
        if (entryA.name === entryB.name) return 0;
        if (entryA.name < entryB.name) return this.sortDesc ? -1 : 1;
      });
    },
    sort(index) {
      if (this.sorting === index) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sorting = index;
      }
      if (index === 0) {
        this.counters.sort((entryA, entryB) => {
          if (entryA[index] > entryB[index]) return this.sortDesc ? 1 : -1;
          if (entryA[index] === entryB[index]) return 0;
          if (entryA[index] < entryB[index]) return this.sortDesc ? -1 : 1;
        });
      } else {
        this.counters.sort((entryA, entryB) => {
          return this.sortDesc ? entryB[index] - entryA[index] : entryA[index] - entryB[index];
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.poke-image {
  vertical-align: middle;
  height: 64px;
  min-height: 64px;
  min-width: 64px;
  padding-right: 4px;
}
.pokemon-wrapper span {
  position: relative;
  display: inline-block;
}
.shadow-icon {
  height: 24px;
  position: absolute;
  bottom: 0;
  right: 0;
}
.counter-table {
  thead th {
    cursor: pointer;
  }
  td,
  th {
    vertical-align: middle;
  }
  .align-center {
    text-align: center;
  }
  .pokemon-name {
    font-weight: bold;
  }
  .pokemon-cell {
    padding: 0;
  }
}
</style>
